import { EBpmnElementType, RULES, type ILintRule } from "@/entities/Score";

export function lintEvent(events: any[]): ILintRule[] {
    const lintResult: ILintRule[] = [];

    for (const element of events) {
        const { bpmnElement } = element;
        const { id, $type } = bpmnElement;

        const isIncoiming: boolean = "incoming" in bpmnElement;
        const isOutgoing: boolean = "outgoing" in bpmnElement;
        const countIncoming = isIncoiming ? bpmnElement.incoming.length : 0;
        const countOutgoing = isOutgoing ? bpmnElement.outgoing.length : 0;

        const isEndEvent = $type === EBpmnElementType.END_EVENT;
        const isIntermediateEvent = [
            EBpmnElementType.INTERMEDIATE_CATCH_EVENT,
            EBpmnElementType.INTERMEDIATE_THROW_EVENT,
            EBpmnElementType.INTERMEDIATE_EVENT,
        ].includes($type);
        const isBoundaryEvent = $type === EBpmnElementType.BOUNDARY_EVENT;

        if (countOutgoing > 1) {
            lintResult.push({ ...RULES.E_MANY_OUTGOING_THREADS, id });
        }

        if (!isIncoiming && !isOutgoing && $type !== EBpmnElementType.BOUNDARY_EVENT) {
            lintResult.push({ ...RULES.NOT_CONNECTED_13, id });
        }

        // Ошибка завершающего события
        if (isEndEvent && countIncoming > 1) {
            lintResult.push({ ...RULES.MANY_INCOMING_CONTROL_THREADS, id });
        }

        // Ошибка промежуточного события
        if (isIntermediateEvent && (!isIncoiming || !isOutgoing)) {
            lintResult.push({ ...RULES.NOT_INCOMING_AND_OUTGOING_THREAD, id });
        }

        // Ошибка граничных событий
        if (isBoundaryEvent && !isOutgoing && !(bpmnElement?.eventDefinitions?.[0]?.$type === "bpmn:CompensateEventDefinition")) {
            lintResult.push({ ...RULES.NO_OUTGOING_THREAD, id });
        }
    }

    return lintResult;
}
