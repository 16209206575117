import { storeToRefs } from "pinia";

import { useCommentStore } from "@/entities/Comment";
import { EOverlayType, useBpmnModelerOverlayStore, useBpmnModelerStore } from "@/entities/BpmnModeler";

export function useInstallation() {
    const commentStore = useCommentStore();
    const bpmnOverlayStore = useBpmnModelerOverlayStore();
    const bpmnModelerStore = useBpmnModelerStore();

    const { commentsElementIds } = storeToRefs(commentStore);
    const { modelerElementRegistry } = storeToRefs(bpmnModelerStore);

    function installationOverlays() {
        for (const row of commentsElementIds.value) {
            const element = modelerElementRegistry.value.get(row.elementId);

            if (element) {
                bpmnOverlayStore.addOverlayData({
                    id: row.elementId,
                    item: {
                        type: EOverlayType.MESSAGE,
                        data: {
                            count: row.count,
                            unreadCount: row.unreadCount,
                        },
                        settings: {
                            open: false,
                        },
                    },
                });
            }
        }
    }

    return {
        installationOverlays,
    };
}
