import { useI18n } from "vue-i18n";
import { ref } from "vue";

import { extractIds } from "@/shared/lib/utils/array";
import type { IPromise } from "@/shared/lib/types";

import { ProcessApi } from "@/entities/Process/api";
import type { IDiagram, IProcess } from "@/entities/Process/model";
import { useConfirmation, useNotification } from "@/shared/lib/composables";
import { useProcessStore } from "@/entities/Process/model";

export function useDeleteProcess() {
    const { showDeleteConfirm } = useConfirmation();
    const { showError } = useNotification();
    const { t } = useI18n();
    const processStore = useProcessStore();

    const promise = ref<IPromise>();

    async function deleteProcesses(processes: IProcess[] | IDiagram[], promiseValue?: IPromise) {
        promise.value = promiseValue;
        const text = processes.length > 1 ? t("are-you-really-want-delete-selected-processes") : t("are-you-really-want-delete-process");
        showDeleteConfirm({
            message: text,
            acceptCb: () => remove(extractIds(processes)),
            hideCb: cancel,
            rejectCb: cancel,
        });
    }

    async function remove(payload: string[]): Promise<void> {
        try {
            await ProcessApi.delete(payload);
            processStore.localeDeleteByIds(payload);
            processStore.clearSelectedProcesses();

            if (promise.value) {
                promise.value.resolve(true);
            }
        } catch (e: any) {
            showError(e?.message || e);
            if (promise.value) {
                promise.value.reject(false);
            }
        }
    }
    function cancel() {
        if (promise.value) {
            promise.value.resolve(false);
        }
    }

    return {
        deleteProcesses,
    };
}
