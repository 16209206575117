<template>
    <Dialog v-model:visible="visible" :header="$t('save-process')" modal :draggable="false" :style="{ width: '30vw' }" position="top">
        <div class="flex flex-column gap-3">
            <div class="flex flex-column gap-1">
                <label for="name">{{ $t("name") }}</label>
                <InputText id="name" v-model="state.name" :placeholder="$t('name')" autofocus />
            </div>
            <div class="flex flex-column gap-1">
                <label for="tags">{{ $t("Tags") }}</label>
                <TagSelect v-model="state.tags" />
            </div>
            <div class="flex flex-column gap-1">
                <label for="folderName">{{ $t("folder-name") }}</label>
                <div class="w-full flex gap-2">
                    <FolderSearch v-model="state.folder" class="w-full" />
                    <Button icon="pi pi-plus" outlined @click="createFolder" />
                </div>
            </div>
            <div class="flex flex-column gap-1 align-items-start">
                <div class="flex gap-1 align-items-center">
                    <label for="type">{{ $t("type-of-process") }}</label>
                    <span class="pi pi-info-circle p-1 hover:surface-100 border-round" v-tooltip.top="$t('you-can-link-diagrams')"></span>
                </div>
                <div class="w-full flex align-items-center gap-4">
                    <SelectButton
                        v-model="state.processType"
                        :options="processTypeItems"
                        optionLabel="label"
                        optionValue="value"
                        optionDisabled="disabled"
                        id="type"
                    />
                    <Button v-if="state.processType" text :label="textDuplicateProcess" v-tooltip.top="$t('duplicate-to-visual-compare')" />
                </div>
            </div>
            <div class="flex flex-column gap-1" v-if="state.processType">
                <label for="comment">{{ $t("linked-diagram") }}</label>
                <Dropdown
                    v-model="state.linkedDiagramId"
                    :options="diagramCallActivitySearch"
                    option-label="name"
                    option-value="id"
                    :placeholder="$t('process-name')"
                    filter
                    showClear
                    filter-icon="pi pi-search"
                    :filter-placeholder="$t('search')"
                />
            </div>
            <div class="flex flex-column gap-1">
                <label for="comment">{{ $t("version-comment") }}</label>
                <Textarea v-model="state.comment" autoResize :rows="1" :placeholder="$t('version-comment') + '...'" />
            </div>
            <Scoring />
        </div>
        <template #footer>
            <Button :label="$t('cancel')" outlined @click="hide" />
            <Button :label="saveButtonText" @click="save" />
        </template>
    </Dialog>

    <CreateFolder ref="createFolderRef" />
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

import { useDialog } from "@/shared/lib/composables";
import { DEFAULT_DIAGRAM_NAME } from "@/shared/config";

import { FolderSearch } from "@/entities/Folder";
import { TagSelect, type ITag, useTagStore } from "@/entities/Tag";
import { useDiagramStore, EProcessType, type IDiagram, type IUploadDiagram } from "@/entities/Process";
import { useBpmnModelerStore } from "@/entities/BpmnModeler";
import type { IFolder } from "@/entities/Folder/model";

import { CreateFolder } from "@/features/Folder/create";
import { useUploadDiagrams } from "@/features/Process/upload-diagrams";
import { Scoring } from "@/features/Diagram-editor/scoring";

interface IProps {
    saveButtonText: string;
    diagram?: IDiagram;
}
interface IState {
    name: string;
    folder?: number;
    tags: ITag[];
    processType?: EProcessType;
    comment?: string;
    linkedDiagramId?: string;
}

const props = defineProps<IProps>();

const diagramStore = useDiagramStore();
const bpmnModelerStore = useBpmnModelerStore();
const tagStore = useTagStore();

const { visible, show, hide } = useDialog();
const { t } = useI18n();
const { diagram, diagramCallActivitySearch } = storeToRefs(diagramStore);
const { uploadVersion } = useUploadDiagrams();

const state = ref<IState>({
    name: props.diagram?.name || DEFAULT_DIAGRAM_NAME,
    tags: [],
});
const createFolderRef = ref();

const processTypeItems = ref([
    { label: EProcessType.ASIS, value: EProcessType.ASIS },
    { label: EProcessType.TOBE, value: EProcessType.TOBE },
]);

const textDuplicateProcess = computed(
    () => t("duplicate-to") + ` ${state.value.processType === EProcessType.ASIS ? EProcessType.TOBE : EProcessType.ASIS}`,
);

async function createFolder() {
    const result: null | IFolder = await new Promise((resolve, reject) => {
        createFolderRef.value.show(null, { promiseValue: { resolve, reject }, forSearch: true });
    });

    if (result) {
        state.value.folder = result.id;
    }
}
async function save() {
    const { body, svg } = await bpmnModelerStore.getDiagramData();

    const unsavedDiagram: IUploadDiagram = {
        name: state.value.name,
        public: true,
        comment: state.value.comment,
        body,
        svg,
        folderId: state.value.folder,
        tags: state.value.tags,
        processType: state.value.processType,
        linkedDiagramId: state.value.linkedDiagramId,
    };

    if (diagram.value) {
        const data = await uploadVersion(diagram.value.id, unsavedDiagram);
        diagramStore.setDiagram(data as unknown as IDiagram);
    } else {
        await diagramStore.createDiagram(unsavedDiagram);
    }

    hide();
}

async function beforeShow() {
    if (diagram.value) {
        state.value.folder = Number(diagram.value.teamFolderId) > 0 ? Number(diagram.value.teamFolderId) : undefined;
        state.value.tags = diagram.value.tags ? diagram.value.tags : [];
        state.value.processType = diagram.value.processType || undefined;
        state.value.linkedDiagramId = diagram.value.linkedDiagramId || undefined;
        state.value.comment = diagram.value.description || undefined;
    }

    const promises = [tagStore.fetchAll()];

    if (!diagramCallActivitySearch.value) {
        promises.push(diagramStore.fetchCallActivitySearch());
    }

    await Promise.all(promises);

    show();
}

defineExpose({
    show: () => beforeShow(),
});
</script>

<style scoped></style>
