export interface ITeamListSearch {
    admin: string;
    countOfUsers: number;
    name: string;
    teamId: string;
}

export interface IMember {
    id: string;
    lastLogin: null | string | Date;
    memberRights: string; // ENUM
    memberStatus: string; // ENUM
    user: string;
}

export interface ITeam {
    allowedDomain: null;
    apiKey: string;
    iamAdmin: boolean;
    id: string;
    members: IMember[];
    name: string;
    teamDiagramId: null | string;
    teamDiagramName: null | string;
}
export interface ITeamSetting {
    setting: string;
    value: string;
}

export enum ESettingsName {
    SCORING = "scoringSettings",
    MIN_DIAGRAM_SCORE = "minimumDiagramScore",
    MIN_PERCENT_OF_ASSIGNEES = "minumumPercentOfAssignees",
    MIN_PERCENT_OF_SYSTEMS = "minumumPercentOfSystems",
    MIN_PERCENT_OF_DOCUMENTS = "minumumPercentOfDocuments",
}

export interface IGetAdminTeam {
    totalElements: number;
    totalPages: number;
    content: IContent[];
}

export interface IContent {
    name: string;
    id: string;
    iamAdmin: boolean;
    teamDiagramId: any;
    teamDiagramName: any;
    allowedDomain: any;
    apiKey?: string;
    members: IMember[];
}
