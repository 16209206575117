<template>
    <div class="col-3 min-w-card">
        <div class="h-full flex flex-column border-round shadow-2 hover:shadow-5 overflow-hidden">
            <Skeleton class="w-full" height="12rem" />
            <div class="flex flex-column gap-3 p-4">
                <div class="flex flex-column gap-2 align-items-start">
                    <Skeleton width="5rem" height="1.2rem" />
                    <div class="w-full flex gap-3 align-items-start justify-content-between">
                        <Skeleton width="12rem" height="2rem" />
                        <Skeleton width="4rem" height="2rem" />
                    </div>
                </div>
                <div class="flex flex-column gap-2 align-items-start">
                    <div class="flex gap-2">
                        <Skeleton width="2rem" height="1rem" />
                        <Skeleton width="6rem" height="1rem" />
                    </div>
                    <div class="flex gap-2">
                        <Skeleton width="2rem" height="1rem" />
                        <Skeleton width="7rem" height="1rem" />
                    </div>
                    <div class="flex gap-2">
                        <Skeleton width="2rem" height="1rem" />
                        <Skeleton width="3rem" height="1rem" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
