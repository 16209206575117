<template>
    <WrapperBlockColumn>
        <div class="flex flex-column gap-3">
            <div v-html="data?.item?.headline"></div>
            <div v-html="data?.item?.content"></div>
        </div>
        <DataTable :value="data?.item?.features" class="max-w-full">
            <Column field="personal" class="text-center" v-for="item of titleData">
                <template #header>
                    <span class="text-letf" v-if="item == 'Feature'">{{ item }}</span>
                    <span class="text-center" :class="{ 'text-primary': item == data?.item?.highlight }" v-else>{{ item }}</span>
                </template>
                <template #body="slotProps">
                    <div v-if="item == 'Feature'" class="text-left">
                        {{ slotProps.data.Feature }}
                    </div>
                    <div v-else>
                        <span class="material-icons" v-if="slotProps.data[item] === true">check</span>
                    </div>
                </template>
            </Column>
        </DataTable>
    </WrapperBlockColumn>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { WrapperBlockColumn } from "@/shared/ui/marketing";
import type { IBlock } from "@/entities/Interface";

const props = defineProps<{ data: IBlock }>();

const titleData = computed(() => {
    if (props.data.item.features) {
        return Object.keys(props.data?.item?.features[0]);
    }
    return [];
});
</script>

<style scoped></style>
