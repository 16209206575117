<template>
    <Teleport to="body">
        <div ref="modelerContainer" class="editor" id="modeler"></div>
    </Teleport>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from "vue";
import { storeToRefs } from "pinia";

import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn.css";
import "diagram-js-minimap/assets/diagram-js-minimap.css";

import starterXML from "@/shared/assets/bpmn/starter.bpmn?raw";
import { DEFAULT_DIAGRAM_NAME } from "@/shared/config";
import { debounce } from "@/shared/lib/utils/timer";

import { useDiagramStore } from "@/entities/Process";
import { useBpmnModelerStore, useElementStore, useBpmnModelerOverlayStore, EOverlayType } from "@/entities/BpmnModeler";

import { useInstallation } from "@/features/Overlay/installation";

import { useLinterOverlay } from "@/widgets/app/process";

const diagramStore = useDiagramStore();
const bpmnModelerStore = useBpmnModelerStore();
const elementStore = useElementStore();
const bpmnOverlayStore = useBpmnModelerOverlayStore();

const { linterErrors } = useLinterOverlay();
const { installationOverlays } = useInstallation();

const { diagram, isZoom } = storeToRefs(diagramStore);
const { modeler, modelerEventBus, modelerKeyboard, modelerContainer } = storeToRefs(bpmnModelerStore);

const timer = ref();

const unsavedChanges = ref<boolean>(false);

async function saveNewDiagram() {
    const { body, svg } = await bpmnModelerStore.getDiagramData();

    await diagramStore.createDiagram({
        body,
        svg,
        name: DEFAULT_DIAGRAM_NAME,
        public: true,
    });

    subscribeAutosave();
}
async function autoSaveDiagram() {
    if (unsavedChanges.value) {
        if (diagram.value) {
            const { body, svg } = await bpmnModelerStore.getDiagramData();

            await diagramStore.autosave({
                body,
                svg,
                autosaveIndex: diagram.value.autosaveIndex,
            });
        }

        unsavedChanges.value = false;
    }
}
async function subscribeAutosave() {
    if (timer.value) {
        clearInterval(timer.value);
        clearInterval(timer.value);
    }

    timer.value = setInterval(async () => {
        await autoSaveDiagram();
    }, 10000);
}

function subscribeToEvents() {
    const EVENTS_FOR_SAVE = [
        "shape.added",
        "shape.changed",
        "element.click",
        "shape.removed",
        "tokenSimulation.toggleMode",
        "tokenSimulation.simulator.trace",
        "connection.added",
        "connection.removed",
    ];
    // Вынести названия событий и колбеки в ...конфиг?
    const EVENT_SELECTION = "selection.changed";

    modelerEventBus.value.on(EVENT_SELECTION, (e: any) => {
        elementStore.setSelectedElements(e.newSelection);

        for (const element of e.oldSelection) {
            bpmnOverlayStore.deleteOverlaysBy(element.id, EOverlayType.CREATE_COMMENT);
            bpmnOverlayStore.changeStatusCommentByElement(element.id, false);
        }
    });

    modelerEventBus.value.on(EVENTS_FOR_SAVE, (e: any) => {
        const subscribe = debounce(async () => {
            unsavedChanges.value = true;
            await linterErrors(true);
        }, 500);

        subscribe();
    });
}

onMounted(async () => {
    try {
        bpmnModelerStore.initiateModeler();
        modelerKeyboard.value.bind(document);

        if (diagram.value) {
            await modeler.value.importXML(diagram.value.body);

            setTimeout(() => {
                installationOverlays();
            }, 500);
        } else {
            await modeler.value.importXML(starterXML);
        }

        if (isZoom.value) await modeler.value.get("canvas").zoom("fit-viewport");

        document.querySelector(".djs-minimap")?.classList.add("open", "hidden");

        subscribeToEvents();
    } catch (e) {
        console.error(e);
    }

    if (!diagram.value) {
        timer.value = setTimeout(async () => {
            await saveNewDiagram();
        }, 5000);
    } else {
        subscribeAutosave();
    }
});
onBeforeUnmount(async () => {
    clearTimeout(timer.value);
    clearInterval(timer.value);

    if (diagram.value) {
        await autoSaveDiagram();

        diagramStore.resetDiagram();
    }
});
</script>

<style lang="scss">
@import "primeflex/primeflex.scss";

.djs-minimap {
    background-color: white;
    bottom: 20px;
    left: 120px;
    top: inherit !important;
    right: inherit !important;
    width: 200px !important;
    height: 100px !important;
    box-shadow: 0 2px 8px 1px rgb(69, 130, 236);

    .viewport-dom {
        background-color: rgba(255, 166, 0, 0.2);
    }
    .toggle {
        display: none;
    }
    .map {
        width: 100% !important;
        height: 100% !important;
    }
}
.djs-context-pad {
    width: auto;
    background-color: white;
    @include styleclass("shadow-2 p-2 border-round");

    .entry {
        width: auto;
        height: auto;
        @include styleclass("p-1 border-round");
    }

    .group {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

.editor {
    position: absolute;
    top: 175px;
    left: 10px;
    bottom: 10px;
    right: 270px;

    &_fullscreen {
        top: 70px;
    }
}
</style>
