export const API_URL = import.meta.env.VITE_API_URL;
export const BASE_URL = import.meta.env.VITE_BASE_URL;
export const APP_NAME = "StormBPMN";
export const TOKEN_KEY = "user-token";
export const USER_DATA_KEY = "user-data";
export const AD_ATTRIBUTION_KEY = "ad_attribution";
export const PROCESS_VIEW_KEY = "process-view";

export const YANDEX_CAPCHA_URL = "https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-with-polyfills-latest.js";
export const YANDEX_SUGGEST_TOKEN_URL = "https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-token-with-polyfills-latest.js";
export const YANDEX_CLIENT_ID = "905edc440ced481a91bd1e48428d6ae9";
export const YANDEX_METRIKA_ID = "97684282";

export const ID_FOLDER_DEFAULT = 999999999;
export const ID_FOLDER_UNPUBLIC = -1;

export const DEFAULT_DIAGRAM_NAME = "Draft";

export const API_DIRECTUS = "https://cms.stormbpmn.com/items/";
export const IMG_DIRECTUS = "https://cms.stormbpmn.com/assets/";

export const SENTRY_DSN = "https://b53df9cfa1833cd8bfd7a66b42df8e6c@sentry.stormbpmn.com/5";
export const SENTRY_ENEBLED = import.meta.env.VITE_SENTRY == true;

export const TOKEN_DADATA = "026f163e1c27c7d8fe8626857a318db1f97cc4cd";
export const DADATA_CAPCHA_URL = "https://suggestions.dadata.ru/suggestions/api/4_1/rs";

export const DOWNLOAD_NAME = "СЧЕТ_STORMBPMN";

export const MAUTIC_URL = "https://marketing.stormbpmn.com"