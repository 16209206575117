<template>
    <div class="flex gap-2">
        <ButtonGroup>
            <ToggleFullscreen />
            <ToggleCamunda />
            <ToggleMiniMap />
            <ChangeColor />
        </ButtonGroup>
        <ButtonGroup>
            <Button icon="pi pi-undo" size="small" class="h-2rem" outlined v-tooltip.bottom="$t('undo')" />
            <Button icon="pi pi-refresh" size="small" class="h-2rem" outlined v-tooltip.bottom="$t('do-last-action')" />
            <Button icon="pi pi-search-plus" size="small" class="h-2rem" outlined v-tooltip.bottom="$t('zoom-in')" />
            <Button icon="pi pi-search-minus" size="small" class="h-2rem" outlined v-tooltip.bottom="$t('zoom-out')" />
            <Button icon="pi pi-copy" size="small" class="h-2rem" outlined v-tooltip.bottom="$t('copy-part')" />
            <Button icon="pi pi-clipboard" size="small" class="h-2rem" outlined v-tooltip.bottom="$t('paste-part')" />
        </ButtonGroup>
        <ButtonGroup>
            <ToggleCheckErrors />
            <Button icon="pi pi-list" size="small" class="h-2rem" outlined v-tooltip.bottom="$t('auto-numbering')" />
            <Button icon="pi pi-ban" size="small" class="h-2rem" outlined v-tooltip.bottom="$t('clear-numbering')" />
        </ButtonGroup>
        <Button icon="pi pi-arrow-right" size="small" class="h-2rem" outlined v-tooltip.bottom="$t('show-sidebar')" />
        <Button icon="pi pi-question-circle" size="small" severity="info" class="h-2rem" outlined v-tooltip.bottom="$t('help')" />
    </div>
</template>

<script setup lang="ts">
import { watch, onUnmounted } from "vue";
import { storeToRefs } from "pinia";

import ButtonGroup from "primevue/buttongroup";

import { useDiagramActionMenuStore } from "@/entities/Process";
import { EOverlayType, useBpmnModelerOverlayStore } from "@/entities/BpmnModeler";

import { ToggleCamunda } from "@/features/Diagram-editor/toggle-camunda";
import { ToggleFullscreen } from "@/features/Diagram-editor/toggle-fullscreen";
import { ToggleMiniMap } from "@/features/Diagram-editor/toggle-mini-map";
import { ChangeColor } from "@/features/Diagram-editor/change-color";
import { ToggleCheckErrors } from "@/features/Diagram-editor/toggle-check-errors";

import { useLinterOverlay } from "@/widgets/app/process";

const actionMenuStore = useDiagramActionMenuStore();
const BpmnModelerOverlayStore = useBpmnModelerOverlayStore();

const { isCheckErrors } = storeToRefs(actionMenuStore);

const { linterErrors } = useLinterOverlay();

watch(isCheckErrors, async value => {
    if (value) {
        await linterErrors();
    } else {
        BpmnModelerOverlayStore.deleteOverlaysBy(undefined, EOverlayType.LINTER);
    }
});

onUnmounted(() => {
    actionMenuStore.resetMenu();
});
</script>

<style scoped></style>
