<template>
    <Button
        icon="pi pi-map"
        size="small"
        class="h-2rem"
        :outlined="!isMinimap"
        v-tooltip.bottom="$t('open-minimap')"
        @click="diagramActionMenuStore.toggleMinimap"
    />
</template>

<script setup lang="ts">
import { useDiagramActionMenuStore } from "@/entities/Process";
import { storeToRefs } from "pinia";

const diagramActionMenuStore = useDiagramActionMenuStore();
const { isMinimap } = storeToRefs(diagramActionMenuStore);
</script>

<style scoped></style>
