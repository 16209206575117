import { ref } from "vue";

import { useNotification } from "@/shared/lib/composables";

import { CommentApi, useCommentStore, type IComment } from "@/entities/Comment";
import { useBpmnModelerOverlayStore } from "@/entities/BpmnModeler";

export function useCommentMakeRead() {
    const commentStore = useCommentStore();
    const bpmnOverlaysStore = useBpmnModelerOverlayStore();

    const { showError } = useNotification();

    const timer = ref();

    async function makeReadComments(comments: IComment[]): Promise<void> {
        try {
            if (comments.length === 0) return;

            const ids: string[] = [];

            for (const comment of comments) {
                if (!comment.author.you && !comment.readed) {
                    ids.push(comment.id);
                }
            }
            await CommentApi.makeRead(ids);

            if (timer.value) clearTimeout(timer.value);

            timer.value = setTimeout(() => {
                for (const comment of comments) {
                    if (!comment.author.you && !comment.readed) {
                        commentStore.replaceComment({ ...comment, readed: true });
                    }
                }

                const elementId = comments[0].elementId;
                if (elementId) {
                    bpmnOverlaysStore.chnageDataCommentByElement(elementId, {
                        count: comments.length,
                        unreadCount: 0,
                    });
                }
            }, 2000);
        } catch (e) {
            showError(e);
        }
    }

    return {
        makeReadComments,
    };
}
