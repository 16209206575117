import { httpClient } from "@/shared/api";
import type { AxiosResponse } from "axios";
import type { ITeam, ITeamListSearch, ITeamSetting, IGetAdminTeam, IContent } from "..";

export class TeamApi {
    static async fetchForFilter(): Promise<ITeamListSearch[]> {
        const response: AxiosResponse = await httpClient.get("/diagram-search/team-list");
        return response.data;
    }
    static async fetchMy(): Promise<ITeam> {
        const response: AxiosResponse = await httpClient.get("/team");
        return response.data;
    }
    static async fetchSettings(): Promise<ITeamSetting[]> {
        const response: AxiosResponse = await httpClient.get("/team/settings");
        return response.data;
    }
    static async getAdminTeam(): Promise<IGetAdminTeam> {
        const response: AxiosResponse = await httpClient.get("/admin/teams");
        return response.data;
    }
    static async promoteToAdmin(memberId: string): Promise<IContent> {
        const response: AxiosResponse = await httpClient.post("/admin/promoteToAdmin?memberId=" + memberId);
        return response.data;
    }
    static async addToTeam(userEmail: string, teamId: string | undefined): Promise<IContent> {
        const response: AxiosResponse = await httpClient.post("/admin/addUserToTeam?userEmail=" + userEmail + "&teamId=" + teamId);
        return response.data;
    }
    static async searchByTeamName(filter: string) {
        const response: AxiosResponse = await httpClient.get("/admin/team/search?name=" + filter);
        return response.data;
    }
}
