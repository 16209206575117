import { useSessionStore } from "@/entities/Session";
import { setTitlePage } from "@/shared/lib/browser";
import { ERoutesName } from ".";

import { type NavigationGuardNext, type RouteRecordRaw } from "vue-router";

export const requiredAuth = (to: any, from: any, next: NavigationGuardNext) => {
    const sessionStore = useSessionStore();

    if (sessionStore.isAuth) {
        return next();
    }

    next({ name: ERoutesName.LOGIN, query: { redirectUrl: to.path } });
};
export const notRequiredAuth = (to: any, from: any, next: NavigationGuardNext) => {
    const sessionStore = useSessionStore();

    if (sessionStore.isAuth) {
        return next({ name: ERoutesName.APP_MY_PROCESSES });
    }

    next();
};
export const beforeEach = (to: any, from: any, next: NavigationGuardNext) => {
    const title = to.meta.title;
    if (title) setTitlePage(title);

    next();
};
