<template>
    <div class="w-full flex flex-column align-items-center p-2 px-3 border-round border-1" :class="styleForScoring">
        <h4 class="text-3xl m-0">{{ score }} / 10</h4>
        <div class="flex gap-2 align-items-center">
            <span>{{ $t("process-quality-rating") }}</span>
            <span class="pi pi-question-circle"></span>
        </div>
        <MeterGroup
            :value="meterGroup"
            :max="countOfElements"
            class="w-full mt-2"
            :pt="{ metercontainer: () => ({ class: ['h-auto border-round overflow-auto'] }) }"
        >
            <template #meter="{ value, size }">
                <div
                    class="flex justify-content-center px-2 text-sm text-white gont-medium"
                    :class="[value.color]"
                    :style="{ width: size }"
                >
                    {{ value.value }}
                </div>
            </template>
            <template #label>
                <span></span>
            </template>
        </MeterGroup>
    </div>
</template>

<script setup lang="ts">
import { onMounted, computed } from "vue";
import { storeToRefs } from "pinia";

import MeterGroup, { type MeterItem } from "primevue/metergroup";

import { useBpmnModelerStore } from "@/entities/BpmnModeler";
import { useTeamStore } from "@/entities/Team";

import { useScoringStore } from "..";

const scoringStore = useScoringStore();
const bpmnModelerStore = useBpmnModelerStore();
const teamStore = useTeamStore();

const { score, errors, warnings, infos, countOfElements } = storeToRefs(scoringStore);
const { scoringSettings } = storeToRefs(teamStore);

const meterGroup = computed<MeterItem[]>(() => {
    const arr: MeterItem[] = [];

    const successCount: number = countOfElements.value - errors.value.length - warnings.value.length - infos.value.length;

    if (successCount > 0) {
        arr.push({ label: "", value: successCount, color: "bg-green-400", icon: "" });
    }
    if (infos.value.length) {
        arr.push({ label: "", value: infos.value.length, color: "bg-cyan-400", icon: "" });
    }
    if (warnings.value.length) {
        arr.push({ label: "", value: warnings.value.length, color: "bg-yellow-400", icon: "" });
    }
    if (errors.value.length) {
        arr.push({ label: "", value: errors.value.length, color: "bg-red-400", icon: "" });
    }

    return arr;
});

const styleForScoring = computed(() => {
    if (score.value < 3) return "bg-red-50 text-red-700 border-red-300";
    if (score.value < 8) return "bg-yellow-70 text-yellow-700 border-yellow-300";
    return "bg-green-50 text-green-600 border-green-300";
});

onMounted(async () => {
    const xml: string = await bpmnModelerStore.saveXML();
    await scoringStore.calculateScore(xml, scoringSettings.value);
});
</script>

<style scoped>
.bg-yellow-70 {
    background-color: #f9f4e4;
}
</style>
