<template>
    <div class="diagram-right-sidebar">
        <div class="p-3">
            <h3 class="mb-2">Комментарии</h3>
            <ButtonGroup>
                <Button
                    v-for="item of buttons"
                    class="my-button"
                    :icon="item.icon"
                    size="small"
                    :outlined="value !== item.value"
                    :disabled="item.disabled"
                    severity="secondary"
                    @click="value = item.value"
                />
            </ButtonGroup>
        </div>
        <div v-if="isLoading || isLoadingGet" class="comments scroll text-sm">
            <div class="px-3 flex flex-column gap-2">
                <div class="flex gap-2">
                    <Skeleton class="surface-200" width="8rem" height="0.7rem" />
                    <Skeleton class="surface-200" width="4rem" height="0.7rem" />
                </div>
                <Skeleton class="surface-200" width="14rem" height="1rem" />
                <Skeleton class="surface-200" width="16rem" height="1rem" />
                <Skeleton class="surface-200" width="10rem" height="1rem" />
                <div class="flex gap-2">
                    <Skeleton class="surface-200" width="5rem" height="5rem" />
                    <Skeleton class="surface-200" width="5rem" height="5rem" />
                    <Skeleton class="surface-200" width="5rem" height="5rem" />
                </div>
            </div>
        </div>
        <div v-else ref="commentsScrollRightSidebarRef" class="comments scroll text-sm">
            <CommentItem v-for="comment of comments" :key="comment.id" :comment="comment">
                <template #position v-if="comment.elementId">
                    <div class="flex gap-2 align-items-center hover:text-blue-500 cursor-pointer" @click="goToComment(comment.elementId)">
                        <span class="pi pi-search text-sm hover:text-blue-500 hover:font-bold"></span>
                        <span>{{ elementName(comment.elementId) }}</span>
                    </div>
                </template>
                <template #actions>
                    <CommentActions @click.stop="toggleCommentMenu(comment, $event)" />
                </template>
                <template #completed>
                    <CommentToggleCompleted :comment="comment" />
                </template>
                <template #edit>
                    <CommentEdit :comment="comment" @click.stop="" />
                </template>
            </CommentItem>
            <div class="px-3 text-center" v-if="comments.length === 0 && !isLoading">
                <b>Оставьте комментарий, будьте первым</b>
            </div>
        </div>
        <div class="p-3">
            <div v-if="selectedElements.length > 1" class="border-round text-lg bg-red-50 border-1 border-red-300 w-full px-2 py-2">
                <span class="text-sm text-red-500 ml-1"> Недоступно </span>
            </div>
            <CreateCommentForm
                v-else
                v-model:comment="commentModel"
                v-model:files="files"
                :is-loading="isLoadingCreate"
                :is-border="true"
                @send-comment="onSendComment"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from "vue";
import { storeToRefs } from "pinia";

import ButtonGroup from "primevue/buttongroup";

import { useLoading, useNotification } from "@/shared/lib/composables";

import { CreateCommentForm, CommentItem, useCommentStore } from "@/entities/Comment";
import { useDiagramStore } from "@/entities/Process";
import { useBpmnModelerStore, useElementStore } from "@/entities/BpmnModeler";

import { CommentActions, useCommentMenu } from "@/features/Comment/menu";
import { useSendComment } from "@/features/Comment/send";
import { CommentToggleCompleted } from "@/features/Comment/toggle-completed";
import { CommentEdit } from "@/features/Comment/edit";
import { useCommentMakeRead } from "@/features/Comment/make-read";
import { scrollToBottom } from "@/shared/lib/browser";

const commentStore = useCommentStore();
const diagramStore = useDiagramStore();
const bpmnModelerStore = useBpmnModelerStore();
const elementStore = useElementStore();

const { diagram } = storeToRefs(diagramStore);
const { isLoadingGet, comments, commentsScrollRightSidebarRef, commentsScrollRef } = storeToRefs(commentStore);
const { modelerCanvas, modelerElementRegistry } = storeToRefs(bpmnModelerStore);
const { selectedElements } = storeToRefs(elementStore);

const { isLoading, startLoading, finishLoading } = useLoading();
const { showWarning } = useNotification();
const { toggleCommentMenu } = useCommentMenu();
const { commentModel, files, isLoading: isLoadingCreate, sendComment } = useSendComment();
const { makeReadComments } = useCommentMakeRead();

const value = ref<string>("comments");
const buttons = ref([
    {
        icon: "pi pi-objects-column",
        value: "overlays",
        disabled: true,
    },
    {
        icon: "pi pi-pen-to-square",
        value: "detail",
        disabled: true,
    },
    {
        icon: "pi pi-arrow-right-arrow-left",
        value: "compare",
        disabled: true,
    },
    {
        icon: "pi pi-sitemap",
        value: "architecture",
        disabled: true,
    },
    {
        icon: "pi pi-link",
        value: "links",
        disabled: true,
    },
    {
        icon: "pi pi-comments",
        value: "comments",
        disabled: false,
    },
]);

const selectedElement = computed<null | string>(() => (selectedElements.value.length === 1 ? selectedElements.value[0].id : null));

function goToComment(elementId: null | string) {
    if (elementId) {
        const element = modelerElementRegistry.value.get(elementId);
        if (!element) {
            showWarning("Элемент не найден");
            return;
        }

        const viewbox = modelerCanvas.value.viewbox();
        const box = element;

        const newViewbox = {
            x: box.x + box.width / 2 - viewbox.outer.width / 2,
            y: box.y + box.height / 2 - viewbox.outer.height / 5,
            width: viewbox.outer.width,
            height: viewbox.outer.height,
        };

        modelerCanvas.value.viewbox(newViewbox);

        modelerCanvas.value.zoom(1.2);

        elementStore.selectElement(elementId);

        //bpmnOverlaysStore.resetStatusCommentByAll();
        //bpmnOverlaysStore.changeStatusCommentByElement(elementId, true);
    }
}

function elementName(elementId: string): string {
    const element = elementStore.getElementById(elementId);

    if (element) {
        return element.businessObject.name || elementId;
    }

    return elementId;
}

async function onSendComment() {
    await sendComment(selectedElement.value);
    scrollToBottom(commentsScrollRightSidebarRef.value);
    if (commentsScrollRef.value) {
        scrollToBottom(commentsScrollRef.value);
    }
}

watch(selectedElements, async value => {
    startLoading();

    if (value.length > 0) {
        if (diagram.value?.id) {
            await commentStore.getCommentsBy(
                diagram.value.id,
                value.map(item => item.id),
            );

            await makeReadComments(comments.value);
        }
    } else {
        if (diagram.value?.id) {
            await commentStore.getCommentsBy(diagram.value.id);
        }
    }

    finishLoading();
});

watch(commentsScrollRightSidebarRef, async value => {
    if (value) scrollToBottom(commentsScrollRightSidebarRef.value);
});
</script>

<style lang="scss" scoped>
@import "primeflex/primeflex.scss";

.diagram-right-sidebar {
    width: 260px;
    @include styleclass("surface-50 border-1 border-200 border-round flex flex-column gap-2");
}

.my-button {
    @include styleclass("p-2");
    width: 35px;
}
.comments {
    @include styleclass("flex-auto flex flex-column overflow-auto");
}
</style>
