import type { AxiosResponse } from "axios";
import type { ICreateComment, IComment, ICommentCount, ICommentFile, IEditComment } from "../model/types";
import { httpClient } from "@/shared/api";

export class CommentApi {
    static async create(payload: ICreateComment): Promise<IComment> {
        const response: AxiosResponse = await httpClient.post("/comment", payload);
        return response.data;
    }
    static async uploadFilesByComment(commentId: string, formData: FormData): Promise<ICommentFile> {
        const response: AxiosResponse = await httpClient.post(`/files/attach-to-comment/${commentId}`, formData);
        return response.data;
    }
    static async toggleImportantById(commentId: string) {
        const response: AxiosResponse = await httpClient.put(`/comment/important/${commentId}`);
        return response.data;
    }
    static async toggleCompleted(commenId: string, diagramId: string): Promise<void> {
        await httpClient.put(`/comment/${diagramId}/null/${commenId}`);
    }

    static async editCommentById(commentId: string, payload: IEditComment): Promise<IComment> {
        const response: AxiosResponse = await httpClient.post(`/comment/${commentId}`, payload);
        return response.data;
    }
    static async makeRead(commentIds: string[]): Promise<void> {
        await httpClient.post(`/comment/makeRead`, commentIds);
    }

    static async getCountByDiagram(diagramId: string): Promise<ICommentCount[]> {
        const response: AxiosResponse = await httpClient.get(`/comment/count-by-diagram/${diagramId}`);
        return response.data;
    }
    static async getByDiagramId(diagramId: string): Promise<IComment[]> {
        const response: AxiosResponse = await httpClient.get(`/comment`, {
            params: {
                diagramId,
                page: 1,
            },
        });
        return response.data;
    }
    static async getByElementId(diagramId: string, elementId: string): Promise<IComment[]> {
        const response: AxiosResponse = await httpClient.get(`/comment/${diagramId}/${elementId}`);
        return response.data;
    }
    static async getSuggestionsByDiagramId(diagramId: string): Promise<string[]> {
        const response: AxiosResponse = await httpClient.get("/comment/getSuggestions", { params: { diagramId } });
        return response.data;
    }

    static async removeById(diagramId: string, elementId: null | string, commentId: string): Promise<void> {
        await httpClient.delete(`/comment/${diagramId}/${elementId}/${commentId}`);
    }
}
