<template>
    <div class="col-3 min-w-card">
        <div class="h-full flex flex-column justify-content-between border-round shadow-2 hover:shadow-5">
            <div
                class="w-full h-12rem flex align-items-center justify-content-center border-bottom-1 surface-border"
                :class="{ 'px-3': imageExist, 'surface-hover': !imageExist }"
            >
                <Image v-if="imageExist" :src="imageUrl" @error="imageExist = false" />
                <span class="pi pi-exclamation-triangle text-7xl text-300" v-else></span>
            </div>
            <div class="flex-auto flex flex-column gap-3 p-4">
                <div class="flex flex-column gap-2 align-items-start">
                    <slot name="status"></slot>
                    <div class="w-full flex gap-3 align-items-center justify-content-between">
                        <router-link
                            :to="{ name: ERoutesName.APP_DIAGRAM_ITEM, params: { id: process.id } }"
                            class="text-primary no-underline hover:bg-primary-100 cursor-pointer px-1 border-round"
                        >
                            <h3>{{ process.name }}</h3>
                        </router-link>
                        <slot name="menu">
                            <span class="pi pi-ellipsis-h"></span>
                        </slot>
                    </div>
                </div>
                <div class="flex flex-column gap-2 align-items-start">
                    <div class="flex gap-2 align-items-center">
                        <Avatar :username="process.authorUsername" />
                        {{ process.authorUsername }}
                    </div>
                    <div class="flex gap-2">
                        <span class="pi pi-calendar"></span>
                        {{ formatDateTime(process.createdOn) }}
                    </div>
                    <div class="flex gap-2 align-items-center" v-if="process.quality">
                        <span class="pi pi-star"></span>
                        <Badge :value="process.quality" severity="success"></Badge>
                    </div>
                    <div class="flex gap-2 align-items-center" v-if="process.teamFolderId">
                        <span class="pi pi-folder"></span>
                        <span class="py-1 px-2 border-round surface-100 border-1 border-200">{{ process.teamFolderName }}</span>
                    </div>
                </div>
            </div>
            <div class="flex flex-column p-4 relative">
                <Divider />
                <TagSelect v-model="tags" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";

import { ERoutesName } from "@/app/providers";

import { formatDateTime } from "@/shared/lib/utils/date";
import { API_URL } from "@/shared/config";
import { Avatar } from "@/shared/ui/avatar/";

import { TagSelect, useTagStore, type ITag } from "@/entities/Tag";
import { type IProcess } from "@/entities/Process/model";

type TProps = {
    process: IProcess;
};

const tagStore = useTagStore();

const props = defineProps<TProps>();

const imageExist = ref(true);
const tags = ref<ITag[]>(props.process.tags);

const imageUrl = computed(() => `${API_URL}files/${props.process.id}_preview.png`);

watch(
    tags,
    async value => {
        await tagStore.updateByDiagramId(props.process.id, tags.value);
    },
    { deep: true },
);
</script>

<style scoped></style>
