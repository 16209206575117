export interface IDirectusResponse<Type> {
    data: Type;
}

export interface IDirectusNavigation {
    title: string;
    status: string;
    id: string;
    copyright: null | string;
    items: IDirectusNavigationItem[];
}

export interface IDirectusNavigationItem {
    id: string;
    sort: number;
    title: string;
    has_children: boolean;
    icon: null | string;
    label: string;
    type: null | EDirectusNavigationItemType;
    open_in_new_tab: boolean;
    external_url?: string;
    url: null | string;
    content: null | string;
    page: null | IDirectusNavigationItemPage;
    children: IDirectusNavigationItem[];
    parent: null | IDirectusNavigationItem;
    last?: boolean;
}

export enum EDirectusNavigationItemType {
    PAGE = "page",
    URL = "url",
}

export interface IDirectusNavigationItemPage {
    id: string;
    sort: number;
    title: string;
    permalink: string;
    blocks: IDirectusNavigationBlock[] | number[];
    seo: IDirectusPageSeo;
}

export interface IDirectusNavigationBlock {
    item: string;
    collection: string;
}

export interface IDirectusPageSeo {
    id: string;
    title: string;
    meta_desciription: string;
    canonical_url: string;
    no_index: boolean | null;
    no_follow: boolean | null;
    og_image: string;
    sitemap_change_frequency: null | string;
    sitemap_priority: null | string;
    og_title: string;
    og_description: string;
    og_type: string;
    og_sitename: string;
}

export interface IResponsePayCourse {
    id: number;
}

export interface IResponsePromocode {
    code: string;
    discount: number;
    id: number;
}
