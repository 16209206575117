<template>
    <SelectButton v-model="view" :options="viewList" option-value="value" :allowEmpty="false">
        <template #option="slotProps">
            <i class="pi" :class="slotProps.option.icon"></i>
        </template>
    </SelectButton>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";

import { useProcessView } from "..";

const processViewStore = useProcessView();
const { viewList, view } = storeToRefs(processViewStore);
</script>

<style scoped></style>
