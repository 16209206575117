import type { ITag } from "@/entities/Tag";

type TOptionalString = null | string;
type TOptionalNumber = undefined | number;
export interface IGeneralProcess {
    authorUsername: string;
    createdOn: string | Date;
    description: null | string;
    id: string;
    name: string;
    type: string; // enum
    tags: ITag[];
    updatedOn: null | string | Date;
    userFolderName: null | string;
    processType: null | EProcessType;
    versionNumber: number;
    you: boolean;
    public: boolean;
    status: EProcessStatus;
    teamFolderId: null | number;
    teamFolderName: null | string;
    favoritesCount: number;
    linkedDiagramId: null | string;
    linkedDiagramName: null | string;
}
export interface IProcess extends IGeneralProcess {
    updatedBy: string;
    lastVersionComment: null | string;
    quality: number;
    secureCreateDate: null | Date;
    secureId: null | string;
    secureType: null | string;
    teamName: string;
    totalCommentsCount: null | number;
    unreadCommentsCountByYou: null | number;
}
export interface IDiagram extends IGeneralProcess {
    autosaveIndex: number;
    body: string;
    falseApprovals: null;
    favored: boolean;
    incommingLinks: null;
    outcommingLinks: null;
    totalApprovals: null | number;
    trueApprovals: null;
    userFolderId: null;
    updateBy: string;
}

export interface IAutosaveDiagram {
    body: string;
    svg: string;
    autosaveIndex: number;
}
export interface IResponseAutosaveDiagram {
    autosaveIndex: number;
    message: string;
}

export interface IProcessResponse {
    returnDiagrams: IProcess[];
    totalElements: number;
}
export interface IOnePorcessResponse {
    diagram: IDiagram;
    permission: EProcessSecure;
}
export interface IProcessQuery {
    team: boolean;
    directAccess?: boolean;
    enterpriseAccess?: boolean;
    emptyFolder?: boolean;
    folderId?: number;
    page: number;
    apiKey?: string;
}

export interface ISearchProcessResponse {
    content: IProcess[];
    totalElements: number;
    totalPages: number;
}
export interface ISearchProcessQuery {
    containText: TOptionalString;
    teamId: TOptionalString;
    folderId: TOptionalString;
    tag: TOptionalString;
    assetId: TOptionalString;
    status: TOptionalString;
    authorId: TOptionalString;
    assigneeId: TOptionalString;
    quality_gt: TOptionalNumber;
    page: number;
}
export interface IUploadDiagram {
    body: undefined | null | string | ArrayBuffer;
    svg?: undefined | null | string | ArrayBuffer;
    comment?: string;
    createdOn?: null;
    description?: null | string;
    name: string;
    public: boolean;
    updatedOn?: null;
    folderId?: null | number;
    tags?: null | ITag[];
    processType?: EProcessType;
    linkedDiagramId?: string;
}
export interface IVersion {
    author: string;
    comment: null | string;
    createdOn: null | string | Date;
    description: null | string;
    diagramId: string;
    id: number;
    name: string;
    type: string; // ENUM;
    versionNumber: number;
}
export interface IProcessSecure {
    anonymousSettings: boolean;
    createdBy: string;
    createdOn: string | Date;
    currentUser: boolean;
    diagramId: null | string;
    diagramName: null | string;
    enterpriseSettings: boolean;
    folderId: null | number;
    folderName: null | string;
    id: string;
    settings: null | EProcessSecure;
    userEmail: string;
}
export interface IProcessSetSecure {
    diagramId: null | string;
    userEmails?: string[];
    folderId?: null | number;
    settings: EProcessSecure;
    anonymousSettings: boolean;
}

export enum EProcessStatus {
    NEW = "NEW",
    IN_PROGRESS = "IN_PROGRESS",
    APPROVAL = "APPROVAL",
    DONE = "DONE",
}
export enum EProcessStatusName {
    NEW = "Новый",
    IN_PROGRESS = "В работе",
    APPROVAL = "На согласовании",
    DONE = "Готов",
}
export enum EProcessSecure {
    NONE = "NONE",
    VIEW = "VIEW",
    EDIT = "EDIT",
    COMMENT = "COMMENT",
}
export enum EProcessType {
    ASIS = "ASIS",
    TOBE = "TOBE",
}
