<template>
    <div class="flex flex-column gap-1 align-items-start">
        <b class="text-xl" v-tooltip.bottom="diagram?.name">{{ diagramName }}</b>
        <div class="flex gap-2 text-sm" :class="{ 'flex-column': !isAuth }">
            <div v-if="diagramFolder" class="flex gap-1 text-primary" v-tooltip.bottom="diagram?.teamFolderName">
                <span class="pi pi-folder"></span>
                <span class="font-medium">{{ diagramFolder }}</span>
            </div>
            <div class="flex gap-2">
                <span class="font-semibold" v-if="diagramUser">{{ diagramUser }}</span>
                <div v-if="whenAutoSave" class="flex gap-1 align-items-end text-green-500 ml-1">
                    <span class="pi pi-cloud-upload"></span>
                    <span>{{ $t("auto-saved") }}</span>
                    <span>{{ formatDateTime(whenAutoSave) }}</span>
                </div>
                <span v-else class="white-space-nowrap">{{ diagramUpdated }}</span>
            </div>
        </div>
        <div class="flex gap-3 align-items-center mt-1">
            <ButtonGroup>
                <Button
                    v-if="isAuth"
                    icon="pi pi-save"
                    size="small"
                    class="h-full"
                    severity="secondary"
                    outlined
                    v-tooltip.bottom="buttonSaveText"
                    @click="onOpenSaveAsProcess"
                />
                <Button :label="$t('download')" icon="pi pi-download" size="small" class="h-full" severity="secondary" outlined />
                <Button
                    v-if="diagram && isAuth"
                    label="Поделиться"
                    icon="pi pi-share-alt"
                    size="small"
                    class="h-full"
                    severity="secondary"
                    outlined
                    @click="() => onShareProcess(diagram)"
                />
                <Button
                    v-if="isFullAccess && diagram && isAuth"
                    :label="$t('To-template')"
                    icon="pi pi-clone"
                    size="small"
                    class="h-full"
                    severity="secondary"
                    outlined
                ></Button>
                <Button
                    v-if="diagram && isAuth"
                    icon="pi pi-trash"
                    size="small"
                    class="h-full"
                    severity="danger"
                    v-tooltip.bottom="$t('delete-process')"
                    @click="() => onDeleteProcess(diagram)"
                />
            </ButtonGroup>
            <ProcessStatus v-if="diagram && isAuth" :status="diagram.status" @click="() => changeStatusRef.show(diagram?.id)" />
            <Tag v-if="diagramProcessType" class="select-none" :severity="getSeverityProcessTypeBy(diagramProcessType)">
                <div class="flex gap-2 p-1 align-items-center">
                    <span>{{ diagramProcessType }}</span>
                    <RouterLink
                        v-if="diagramLinkIdIcon"
                        :to="{ name: ERoutesName.APP_DIAGRAM_ITEM, params: { id: diagram?.linkedDiagramId } }"
                        class="flex"
                        v-tooltip.right="diagram?.linkedDiagramName"
                    >
                        <span class="cursor-pointer hover:text-red-500" :class="diagramLinkIdIcon"></span>
                    </RouterLink>
                </div>
            </Tag>
        </div>
    </div>

    <ChangeStatus ref="changeStatusRef" />
    <ShareProcess ref="shareProcessRef" />
    <SaveAsProcess ref="saveAsRef" :save-button-text="buttonSaveText" :diagram="diagram" />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import ButtonGroup from "primevue/buttongroup";

import { ERoutesName } from "@/app/providers";

import { DEFAULT_DIAGRAM_NAME } from "@/shared/config";
import { formatDateTime, formatDefaultDateTime } from "@/shared/lib/utils/date";

import { useDiagramStore, ProcessStatus, getSeverityProcessTypeBy } from "@/entities/Process";
import { useSessionStore } from "@/entities/Session";
import { EProcessSecure, type IDiagram } from "@/entities/Process/model";

import { ChangeStatus } from "@/features/Process/change-status";
import { ShareProcess } from "@/features/Process/share";
import { useDeleteProcess } from "@/features/Process/remove";
import { SaveAsProcess } from "@/features/Process/save-as";

const { t } = useI18n();

const diagramStore = useDiagramStore();
const sessionStore = useSessionStore();
const { deleteProcesses } = useDeleteProcess();
const router = useRouter();

const { diagram, diagramPermissionByMe, whenAutoSave } = storeToRefs(diagramStore);
const { isFullAccess, isAuth } = storeToRefs(sessionStore);

const changeStatusRef = ref();
const shareProcessRef = ref();
const saveAsRef = ref();

const buttonSaveText = computed(() => {
    if (diagram.value) {
        if (diagramPermissionByMe.value === EProcessSecure.EDIT && isAuth.value) {
            return t("save-as-version");
        }
        if (diagramPermissionByMe.value !== EProcessSecure.EDIT && isAuth.value) {
            return t("make-copy");
        }
    }
    return t("common.save");
});

const diagramName = computed(() => {
    if (diagram.value) {
        const { name } = diagram.value;
        return name.length > 40 ? `${name.substring(0, 40)}...` : name;
    }
    return DEFAULT_DIAGRAM_NAME;
});
const diagramUpdated = computed(() => {
    if (diagram.value) {
        return formatDefaultDateTime(diagram.value.updatedOn || diagram.value.createdOn);
    }
    return formatDefaultDateTime(new Date());
});
const diagramFolder = computed(() => {
    if (diagram.value) {
        const { teamFolderName } = diagram.value;
        return teamFolderName && teamFolderName.length > 40 ? `${teamFolderName.substring(0, 40)}...` : teamFolderName;
    }
    return null;
});
const diagramUser = computed(() => diagram.value?.updateBy || null);
const diagramProcessType = computed(() => diagram.value?.processType || null);
const diagramLinkIdIcon = computed(() => (diagram.value?.linkedDiagramId ? "pi pi-link" : undefined));

async function onDeleteProcess(diagram: undefined | IDiagram) {
    if (diagram) {
        const result = await new Promise((resolve, reject) => {
            deleteProcesses([diagram], { resolve, reject });
        });
        if (result) {
            router.replace({ name: ERoutesName.APP_MY_PROCESSES });
        }
    }
}
function onShareProcess(diagram: undefined | IDiagram) {
    if (diagram) {
        shareProcessRef.value.show(diagram.id);
    }
}
function onOpenSaveAsProcess() {
    saveAsRef.value.show();
}
</script>

<style scoped></style>
