import { httpDirectus } from "@/shared/api";
import type { IDirectusNavigation, IDirectusResponse, IResponsePayCourse, IResponsePromocode } from "@/shared/lib/types";
import type { AxiosResponse } from "axios";
import type { IPayloadCourses } from "../model/types";

export class InterfaceApi {
    static async getHeader(): Promise<IDirectusResponse<IDirectusNavigation>> {
        const response: AxiosResponse = await httpDirectus.get("/Navigation/header?fields=*.*.*.*");
        return response.data;
    }
    static async getFooter(): Promise<IDirectusResponse<IDirectusNavigation>> {
        const response: AxiosResponse = await httpDirectus.get("/Navigation/footer?fields=*.*.*.*");
        return response.data;
    }
    static async applyPromocode(promocode: string): Promise<IDirectusResponse<IResponsePromocode[]>> {
        const response: AxiosResponse = await httpDirectus.get(`/Promocode?filter[code][_eq]=${promocode}&fields=*`);
        return response.data;
    }
    static async payCoursePersonal(payload: IPayloadCourses): Promise<IDirectusResponse<IResponsePayCourse>> {
        const response: AxiosResponse = await httpDirectus.post("/Class_submission", payload);
        return response.data;
    }
    static async getInvoiceMail(payload: IPayloadCourses): Promise<IDirectusResponse<IResponsePayCourse>> {
        const response: AxiosResponse = await httpDirectus.post("/Class_submission", payload);
        return response.data;
    }
}
