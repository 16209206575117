<template>
    <Dropdown
        v-model="team"
        :options="teamByFilter"
        :loading="isLoading"
        showClear
        filter
        filterPlaceholder="Поиск"
        option-label="name"
        option-value="teamId"
        placeholder="Не выбрано"
    />
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { storeToRefs } from "pinia";

import Dropdown from "primevue/dropdown";

import { useTeamStore } from "..";

const teamStore = useTeamStore();
const { teamByFilter, isLoading } = storeToRefs(teamStore);

const team = defineModel();

onMounted(() => {
    teamStore.fetchForFilter();
});
</script>

<style scoped></style>
