<template>
    <footer v-if="pageData || route.name != ERoutesName.MARKETING">
        <div class="z-0 mt-8 justify-content-center flex flex-column md:flex-row mt-3 px-8 py-6 border-top-1 border-gray-200 surface-ground">
            <div class="col" style="max-width: 1600px">
                <div class="flex justify-content-between gap-3 flex-wrap">
                    <div v-for="item of footerItem" class="w-15rem">
                        <div v-if="!item?.has_children">
                            <div class="flex gap-1">
                                <span class="material-icons text-xl">{{ item?.icon }}</span>
                                <span class="font-bold" v-html="item?.label"></span>
                            </div>
                            <div v-html="item?.content"></div>
                        </div>
                        <div v-else class="flex flex-column gap-3" v-if="item.children.length > 0">
                            <span class="font-bold">{{ item.label }}</span>
                            <div class="flex flex-column gap-2">
                                <div v-for="btn of item?.children">
                                    <router-link
                                        v-if="btn.type == EDirectusNavigationItemType.PAGE && btn?.page?.permalink"
                                        :to="btn.page?.permalink"
                                    >
                                        {{ btn.title }}
                                    </router-link>
                                    <a v-else-if="btn?.external_url" :href="btn.external_url">
                                        {{ btn.label }}
                                    </a>
                                    <router-link v-else-if="btn.type == EDirectusNavigationItemType.URL && btn?.url" :to="btn?.url">
                                        {{ btn.title }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="token-head" v-tooltip.left="'Лучше бы вам не использовать дорожки.'">
                    <img
                        loading="lazy"
                        height="70"
                        width="70"
                        alt="Голова мастера Токена"
                        title="Голова мастера Токена"
                        class="vertical-pan"
                        src="@/shared/assets/images/png/token_head.webp"
                    />
                </div>
                <div class="text-sm">{{ footerCopyright }}</div>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";

import { useInterfaceStore } from "@/entities/Interface";
import { EDirectusNavigationItemType } from "@/shared/lib/types";
import { useRoute } from "vue-router";
import { ERoutesName } from "@/app/providers";

const interfaceStore = useInterfaceStore();

const { footerItem, footerCopyright, pageData } = storeToRefs(interfaceStore);
const route = useRoute();
</script>

<style scoped>
:deep(.p-button-label) {
    text-align: left;
}
.token-head {
    float: right;
}
.vertical-pan {
    margin-top: -5px;
    position: absolute;
    height: 70px;
    width: 70px;
    z-index: 999;
}
</style>
