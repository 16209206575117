import { computed, ref } from "vue";
import { defineStore, storeToRefs } from "pinia";

import { useLoading, useNotification } from "@/shared/lib/composables";
import { type ITeam, type ITeamSetting, type ITeamListSearch, ESettingsName, TeamApi } from "@/entities/Team";
import { useSessionStore } from "@/entities/Session";

export const useTeamStore = defineStore("team", () => {
    const sessionStore = useSessionStore();

    const { showError } = useNotification();
    const { isLoading, startLoading, finishLoading } = useLoading();
    const { isInTeam } = storeToRefs(sessionStore);

    const teamByFilter = ref<ITeamListSearch[]>([]);
    const team = ref<ITeam>();
    const teamSettings = ref<ITeamSetting[]>();

    const scoringSettings = computed(() => {
        if (teamSettings.value) {
            const findedSetting = teamSettings.value.find(item => item.setting === ESettingsName.SCORING);
            if (findedSetting) return JSON.parse(findedSetting.value);
        }
        return [];
    });
    const minDiagramScore = computed(() => getSettingValueByName(ESettingsName.MIN_DIAGRAM_SCORE));
    const minPercentOfAssignees = computed(() => getSettingValueByName(ESettingsName.MIN_PERCENT_OF_ASSIGNEES));
    const minPercentOfSystems = computed(() => getSettingValueByName(ESettingsName.MIN_PERCENT_OF_SYSTEMS));
    const minPercentOfDocuments = computed(() => getSettingValueByName(ESettingsName.MIN_PERCENT_OF_DOCUMENTS));

    function getSettingValueByName(name: ESettingsName): number {
        if (teamSettings.value) {
            const finded = teamSettings.value.find(item => item.setting === name);
            if (finded) {
                const value = JSON.parse(finded.value);
                return Number.isInteger(value) ? value : 0;
            }
        }

        return 0;
    }

    async function fetchForFilter(): Promise<void> {
        try {
            startLoading();
            teamByFilter.value = await TeamApi.fetchForFilter();
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }
    async function fetchTeam(): Promise<void> {
        try {
            team.value = await TeamApi.fetchMy();
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function fetchTeamSettings(): Promise<void> {
        try {
            if (isInTeam.value) {
                teamSettings.value = await TeamApi.fetchSettings();
            }
        } catch (e: any) {
            showError(e?.message || e);
        }
    }

    return {
        isLoading,
        team,
        teamByFilter,
        teamSettings,
        scoringSettings,
        minDiagramScore,
        minPercentOfAssignees,
        minPercentOfDocuments,
        minPercentOfSystems,
        fetchForFilter,
        fetchTeam,
        fetchTeamSettings,
    };
});
