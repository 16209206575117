<template>
    <div>
        <router-link v-if="data?.type == 'page'" :to="data?.external_url">
            <Button :label="data?.label" :severity="data?.color" :outlined="data?.variant == 'outline'" />
        </router-link>

        <div v-if="data?.type == 'mautic_form'">
            <form class="flex" method="post" :action="MAUTIC_URL + '/form/submit?formId=' + data?.mautic_form_id">
                <div data-validate="email" data-validation-type="email" class="flex gap-2">
                    <InputText
                        placeholder="Ваш e-mail"
                        v-model="email"
                        variant="filled"
                        id="email"
                        name="mauticform[email]"
                        class="flex-auto"
                        type="email"
                    />
                    <Button
                        :disabled="email == ''"
                        type="submit"
                        :label="data?.label"
                        :severity="data?.color"
                        :outlined="data?.variant == 'outline'"
                    />
                </div>

                <input type="hidden" name="return" value="this-must-exist" />
                <input type="hidden" name="mauticform[formId]" :value="data?.mautic_form_id" />
                <input type="hidden" name="formName" value="" />
                <input type="hidden" name="messenger" value="1" />
            </form>
            <p class="text-xs">Без спама, только то, что вы запросили.</p>
        </div>
        <a v-else :href="data?.external_url">
            <Button :label="data?.label" :severity="data?.color" :outlined="data?.variant == 'outline'" />
        </a>
    </div>
</template>

<script setup lang="ts">
import { MAUTIC_URL } from "@/shared/config";
import { ref } from "vue";
import Button from "primevue/button";
const email = ref<string>("");
const props = defineProps({
    data: { type: Object },
});
</script>

<style scoped></style>
