<template>
    <Dialog v-model:visible="visible" :header="$t('share.shareprocess')" modal :draggable="false" :style="{ width: '55vw' }" position="top">
        <div class="flex flex-column gap-5">
            <div class="flex gap-2 align-items-end">
                <div class="w-full flex flex-column gap-1">
                    <b>{{ $t("direct-link") }}</b>
                    <InputText v-model="link" :readonly="true" />
                </div>
                <Button class="w-12rem" :label="$t('copy')" icon="pi pi-copy" @click="copyLink" />
            </div>
            <div class="flex flex-column gap-3">
                <div class="flex gap-2 align-items-end">
                    <Email v-model="emails" :placeholder="$t('share.enteremails')" :caption="false">
                        <template #label>
                            <b>
                                {{ $t("approval-list") }}
                                <Tag
                                    :value="$t('free')"
                                    severity="info"
                                    icon="pi pi-info-circle"
                                    v-tooltip.bottom="$t('you-fellow-need-just-account')"
                            /></b>
                        </template>
                    </Email>
                    <Dropdown v-model="accessLevel" :options="secureAccessLevel" option-label="label" option-value="value" />
                </div>
                <DataTable v-if="hasSharedList" :value="sharedList">
                    <Column :header="$t('email')" field="">
                        <template #body="slotProps">
                            <div class="flex flex-column gap-1">
                                <div class="flex gap-2 align-items-center font-medium">
                                    <span
                                        class="pi"
                                        :class="{ 'pi-user': slotProps.data.currentUser, 'pi-clock': !slotProps.data.currentUser }"
                                    ></span>
                                    <span>{{ slotProps.data.userEmail }}</span>
                                </div>
                                <i class="text-sm text-color-secondary">{{ $t("created-by") }} {{ slotProps.data.createdBy }}</i>
                            </div>
                        </template>
                    </Column>
                    <Column :header="$t('access')">
                        <template #body="slotProps">
                            <Dropdown
                                v-model="slotProps.data.settings"
                                :options="secureAccessLevel"
                                option-label="label"
                                option-value="value"
                                class="w-13rem"
                            />
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button icon="pi pi-times" size="small" text @click="() => deleteSecure(slotProps.data.id)"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="flex flex-column">
                <div class="flex gap-2 align-items-center">
                    <InputSwitch v-model="isAnonymous" @change="changeAnonymousAccess" />
                    <b class="text-xl">{{ $t("anonumous-access") }}</b>
                </div>
                <Message v-if="isAnonymous" severity="error" :closable="false">{{ $t("any-person-can-see-process") }}</Message>
            </div>
            <TabView v-model:active-index="indexTabView" :lazy="true">
                <TabPanel :header="$t('code-for-embedded')">
                    <Textarea v-model="iframeLink" :readonly="true" class="w-full" autoResize />
                </TabPanel>
                <TabPanel :header="$t('Embedded-card')">
                    <div class="flex gap-3 align-items-start">
                        <Textarea v-model="iframeLink" :readonly="true" class="w-full" autoResize />
                        <span class="w-full" v-html="iframeLink"></span>
                    </div>
                </TabPanel>
            </TabView>
        </div>
        <template #footer>
            <template v-if="hasChanged">
                <Button :label="$t('share.cancel')" outlined :loading="isLoading" @click="cancelChanges" />
                <Button :label="$t('save-changes')" :disabled="!diagramId" :loading="isLoading" @click="createSecure" />
            </template>
            <template v-else>
                <Button label="Отмена" outlined @click="hide" />
                <Button :label="$t('grant-access')" :disabled="!emails.length" :loading="isLoading" @click="createSecure" />
            </template>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";

import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputSwitch from "primevue/inputswitch";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

import { useI18n } from "vue-i18n";

import { Email } from "@/shared/ui/email";
import { useDialog, useNotification } from "@/shared/lib/composables";
import { copyToClipboard } from "@/shared/lib/utils/clipboard";
import { EProcessSecure, useSecureProcess } from "@/entities/Process/model";

const { visible, show, hide } = useDialog();
const { showError, showSuccess } = useNotification();
const { t } = useI18n();
const {
    secureAccessLevel,
    isLoading,
    sharedList,
    hasSharedList,
    hasChanged,
    isAnonymous,
    fetchAllByProcessId,
    deleteSecure,
    saveChanges,
    setSecure,
    cancelChanges,
} = useSecureProcess();

const diagramId = ref<string | null>("");
const emails = ref<string[]>([]);
const accessLevel = ref<EProcessSecure>(EProcessSecure.VIEW);
const indexTabView = ref<number>(0);

const link = computed(() => `${window.location.origin}/app/diagram/${diagramId.value}`);
const linkEmbedded = computed(() => `${link.value}/embedded/card`);
const iframeLink = computed(
    () =>
        `<iframe src="${indexTabView.value === 0 ? link.value : linkEmbedded.value}" style="border:1px #f2f2f2 none;" name="extAdmin" scrolling="yes" frameborder="1"  height="340px" width="320px" allowfullscreen></iframe>`,
);

async function beforeShow(id: string) {
    diagramId.value = id;
    indexTabView.value = 0;
    show();
    await fetchAllByProcessId(id);
}

async function createSecure() {
    if (hasChanged.value) {
        await saveChanges();
    } else {
        if (diagramId.value) {
            await setSecure({
                anonymousSettings: false,
                diagramId: diagramId.value,
                folderId: null,
                settings: accessLevel.value,
                userEmails: emails.value,
            });
            emails.value = [];
            accessLevel.value = EProcessSecure.VIEW;
        }
    }

    if (diagramId.value) {
        await fetchAllByProcessId(diagramId.value);
    }
}
async function changeAnonymousAccess() {
    await setSecure({
        diagramId: diagramId.value,
        settings: isAnonymous.value ? EProcessSecure.VIEW : EProcessSecure.NONE,
        anonymousSettings: true,
    });
}

function copyLink() {
    try {
        copyToClipboard(link.value);
        showSuccess(t("link-in-buffer"));
    } catch (e: any) {
        showError(e?.message || e);
    }
}

defineExpose({
    show: (diagramId: string) => beforeShow(diagramId),
});
</script>

<style scoped></style>
