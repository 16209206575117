<template>
    <button class="button" :disabled="isLoading" @click="toggleCompleted(comment)">
        <span class="pi text-xs" :class="[isLoading ? 'pi-spin pi-spinner' : 'pi-check-circle']"></span>
    </button>
</template>

<script setup lang="ts">
import type { IComment } from "@/entities/Comment";
import { useCommentToggleCompleted } from "..";

interface IProps {
    comment: IComment;
}
defineProps<IProps>();

const { isLoading, toggleCompleted } = useCommentToggleCompleted();
</script>
<style scoped lang="scss"></style>
