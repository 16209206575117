import { RULES, type ILintRule } from "@/entities/Score";

export function lintLanes(lanes: any[]): ILintRule[] {
    const lintResult: ILintRule[] = [];

    for (const element of lanes) {
        const {
            bpmnElement: { id },
        } = element;

        lintResult.push({ ...RULES.DONT_USE_TRACKS, id });
    }

    return lintResult;
}
