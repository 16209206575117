import { ref } from "vue";
import { defineStore } from "pinia";
import { InvoiceApi } from "../api";
import { useNotification } from "@/shared/lib/composables";
import { type ICalcPricePayload } from "./types";

export const useInvoiceStore = defineStore("invoice", () => {
    const { showError } = useNotification();

    const price = ref<number>(0);
    const suggestions = ref<any>();

    function resetPrice() {
        price.value = 0;
    }

    async function calcPrice(payload: ICalcPricePayload) {
        try {
            const res = await InvoiceApi.calcPrice(payload);
            price.value = res.totalPrice;
        } catch (e: any) {
            showError(e?.message || e);
        }
    }

    async function getCompany(query: string) {
        try {
            const res = await InvoiceApi.getCompany(query);
            suggestions.value = res.suggestions;
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function downloadInvoicesCourse(payloadCourse: any) {
        try {
            return await InvoiceApi.downloadInvoicesCourse(payloadCourse);
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    return {
        price,
        suggestions,
        resetPrice,
        calcPrice,
        getCompany,
        downloadInvoicesCourse,
    };
});
