import { createRouter, createWebHistory } from "vue-router";
import { routes } from "./routes";
import { beforeEach } from "./guards";

export const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
    strict: true,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 };
    },
});

router.beforeEach(beforeEach);

export * from "./types";
