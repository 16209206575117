<template>
    <div class="surface-section border-right-1 surface-border w-20rem select-none flex flex-column">
        <div class="flex-auto overflow-y-auto">
            <ul class="m-0 px-2">
                <SidebarItem v-for="item in menu" :key="item.label" v-bind="item" />
            </ul>
        </div>
        <div class="w-full flex py-3 justify-content-center">
            <ProcessChangeView />
        </div>
    </div>
</template>

<script setup lang="ts">

import { useI18n } from "vue-i18n";

import { computed } from "vue";

import SidebarItem from "./SidebarItem.vue";

import { PrimeIcons } from "primevue/api";

import { ERoutesName } from "@/app/providers/";



import { ProcessChangeView } from "@/features/Process/change-view";

const { t } = useI18n();

const props = defineProps({
    countApprovals: Number,
});

const menu = computed(() => [
    {
        label: t('new-process'),
        icon: PrimeIcons.PLUS,
        urlName: ERoutesName.APP_DIAGRAM,
    },
    {
        label: t('search'),
        icon: PrimeIcons.SEARCH,
        urlName: ERoutesName.APP_SEARCH,
    },
    {
        label: t('My-diagrams'),
        icon: PrimeIcons.USER,
        urlName: ERoutesName.APP_MY_PROCESSES,
    },
    {
        label: t('my-approvals'),
        icon: PrimeIcons.CHECK_CIRCLE,
        urlName: ERoutesName.APP_APPROVALS,
        badge: props.countApprovals,
    },
    {
        label: t('team-diagrams'),
        icon: PrimeIcons.USERS,
        urlName: ERoutesName.APP_TEAM_PROCESS,
    },
    {
        label: t('shared-with-me'),
        icon: PrimeIcons.SHARE_ALT,
        urlName: ERoutesName.APP_SHARE_WITH_ME,
    },
    {
        label: t('folders'),
        icon: PrimeIcons.FOLDER,
        urlName: ERoutesName.APP_FOLDER,
    },
    {
        label: t('process-map'),
        icon: PrimeIcons.MAP,
        urlName: ERoutesName.APP_PROCESS_MAP,
    },
    {
        label: t('vertex'),
        icon: PrimeIcons.BOX,
        urlName: ERoutesName.APP_VERTEX,
    },
    {
        label: t('trash'),
        icon: PrimeIcons.TRASH,
        urlName: ERoutesName.APP_BASKET,
    },
]);
</script>

<style lang="scss" scoped></style>
