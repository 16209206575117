export enum ERoutesName {
    HOME = "Home",
    SIGN_UP = "SignUp",
    LOGIN = "Login",
    LOGIN_YANDEX = "LoginYandex",
    REQUEST_PASSWORD_RESTORE = "RequestPasswordRestore",
    PASSWORD_RESTORE = "PasswordRestore",
    NOT_FOUND = "NotFound",
    MODELING_IN_BPMN = "ModelingInBpmn",
    ARCHITECTURE_BPMN = "ArchitectureBpmn",
    MANAGE_CAMUNDA = "ManageCamunda",
    ENTERPRISE = "Enterprise",
    SMB = "Smb",
    PERSONAL = "Personal",
    NONPROFITS = "Nonprofits",
    EDUCATION = "Education",
    BPMN_ELEMENTS = "BpmnElements",
    COMPARE_DRAWIO = "CompareDrawio",
    COMPARE_BIZAGI = "CompareBizagi",
    COMPARE_CAMUNDA_MODELER = "CompareCammundaModeler",
    COMPARE_MIRO = "CompareMiro",
    PRICING = "Pricing",
    LICENSE = "License",
    CHECK_EMAIL = "CheckEmail",
    TEAM = "Team",
    MARKETING = "Marketing",
    CONTACT_SALES = "ContactSales",
    BLOG = "Blog",

    APP_DIAGRAM = "AppDiagram",
    APP_DIAGRAM_ITEM = "AppDiagramItem",
    APP_DIAGRAM_ITEM_VERSION = "AppDiagramItemVersion",
    APP_DIAGRAM_EMBEDDED_CARD = "AppDiagramEmbeddedCard",
    APP_SEARCH = "AppSearch",
    APP_MY_PROCESSES = "AppMyProcesses",
    APP_APPROVALS = "AppApprovals",
    APP_TEAM_PROCESS = "AppTeamProcess",
    APP_SHARE_WITH_ME = "AppShareWithMe",
    APP_FOLDER = "AppFolder",
    APP_FOLDER_EMBEDDED = "AppFolderEmbedded",
    APP_PROCESS_MAP = "AppProcessMap",
    APP_VERTEX = "AppVertex",
    APP_BASKET = "AppBasket",
    APP_DIFF_PLANT = "AppPlantDiff",
    APP_DIFF_DIAGRAM = "AppDiagramDiff",
    APP_GENERATE_INVOICE = "GenerateInvoice",
    APP_ADMIN = "Admin",

    ACTIVATION = "Activataion",
}
