type TDate = null | string | number | Date | undefined;

const formatterDateTime = new Intl.DateTimeFormat("ru-RU", {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
});
const formatterDefaultDateTime = new Intl.DateTimeFormat("ru-RU", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
});
const formatterDate = new Intl.DateTimeFormat("ru-RU", {
    day: "numeric",
    month: "long",
    year: "numeric",
});

export function formatDateTime(date: TDate) {
    if (!date) return "Нет даты";
    return formatterDateTime.format(new Date(date));
}
export function formatDefaultDateTime(date: TDate) {
    if (!date) return "Нет даты";
    return formatterDefaultDateTime.format(new Date(date));
}
export function timeAgo(value: TDate) {
    if (!value) return "сейчас";

    const date = value instanceof Date ? value : new Date(value);
    const formatter = new Intl.RelativeTimeFormat("ru");
    const ranges = [
        ["years", 3600 * 24 * 365],
        ["months", 3600 * 24 * 30],
        ["weeks", 3600 * 24 * 7],
        ["days", 3600 * 24],
        ["hours", 3600],
        ["minutes", 60],
        ["seconds", 1],
    ] as const;
    const secondsElapsed = (date.getTime() - Date.now()) / 1000;
    const secondAgo = Math.abs(secondsElapsed) < 1 ? -1 : secondsElapsed;

    for (const [rangeType, rangeVal] of ranges) {
        if (rangeVal <= Math.abs(secondAgo)) {
            const delta = secondAgo / rangeVal;
            return formatter.format(Math.round(delta), rangeType);
        }
    }
}
export function formatDate(date: TDate) {
    if (!date) return "Нет даты";
    return formatterDate.format(new Date(date));
}
