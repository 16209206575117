<template>
    <WrapperBlockColumn>
        <div class="flex flex-column">
            <div v-html="data?.item?.headline"></div>
            <div v-html="data?.item?.content"></div>
        </div>
        <div class="flex flex-column gap-2">
            <div class="flex flex-column gap-5 md:flex-row justify-content-center text-center md:text-left">
                <TabView :pt="{ panelContainer: 'px-0' }">
                    <TabPanel v-for="item in data?.item?.courses">
                        <template #header>
                            <div class="text-left align-items-center">
                                <span class="font-bold white-space-nowrap">{{ item.Courses_id.title }}</span>
                                <br />
                                <span class="font-normal"
                                    >{{ formatDate(item.Courses_id.start_date) }} - {{ formatDate(item.Courses_id.end_date) }}</span
                                >
                            </div>
                        </template>
                        <div class="flex flex-column justify-content-center gap-5 text-left md:flex-row">
                            <Card
                                class="max-h-full"
                                v-for="single_class in item.Courses_id.classes"
                                :pt="{ body: 'h-full', content: 'h-full' }"
                            >
                                <template #content>
                                    <div class="flex flex-column h-full justify-content-between gap-4">
                                        <div class="flex flex-column gap-4">
                                            <div class="text-2xl h-4rem font-bold text-center">{{ single_class.plan.title }}</div>
                                            <div class="h-5rem">{{ single_class.plan.description }}</div>
                                            <div class="">
                                                <div>
                                                    <li v-for="feature in single_class.plan.features">{{ feature }}</li>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="">
                                            <h3>Осталось мест: {{ single_class.max_students - single_class.current_count }}</h3>
                                            <p>{{ single_class.plan.price }} картой</p>
                                            <p>{{ single_class.plan.corporate_price }} по счету</p>
                                            <Button
                                                :outlined="selectedTariff?.id == single_class.id ? false : true"
                                                class="w-full"
                                                :label="selectedTariff?.id == single_class.id ? 'Выбран этот тариф' : 'Выбрать это тариф'"
                                                @click="selectTariff(single_class)"
                                            />
                                        </div>
                                    </div>
                                </template>
                            </Card>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
            <Card class="w-full" v-if="selectedTariff">
                <template #content>
                    <div class="flex flex-column gap-5">
                        <h2>Оплата курса</h2>
                        <div class="flex flex-column gap-3 w-full">
                            <span class="text-left"><b>Форма оплаты</b></span>
                            <Dropdown
                                v-model="typeOfPayment"
                                optionLabel="text"
                                placeholder="Форма оплаты"
                                :options="formOfPayment"
                                optionValue="value"
                                class="text-left"
                            ></Dropdown>
                            <span v-if="typeOfPayment != EFormOfPayment.PERSONAL" class="text-left"><b>Название юр.лица</b></span>
                            <AutoComplete
                                class="w-full"
                                v-model="company"
                                :optionLabel="optionLabelAutoComplete"
                                :suggestions="suggestions"
                                @complete="getCompany"
                                :pt="{ input: 'w-full' }"
                                v-if="typeOfPayment != EFormOfPayment.PERSONAL"
                            >
                                <template #option="item">
                                    <div class="flex flex-column gap-1">
                                        <span>{{ item.option.value }}</span>
                                        <span>{{ "ИНН: " + item.option.data.inn }}</span>
                                    </div>
                                </template>
                            </AutoComplete>
                            <div class="p-inputgroup-addon border-none bg-white p-0">
                                <InputGroup class="w-full flex flex-column gap-2">
                                    <span class="text-left"><b>Почты участников</b></span>
                                    <div class="w-full flex flex-column gap-2">
                                        <div class="w-full flex align-items-center gap-3">
                                            <InputText
                                                placeholder="Электронные почты участников"
                                                v-model="state.email"
                                                class="w-full"
                                                @input="validateInput"
                                                :class="{ 'border-red-400': !correctInputEmail }"
                                                @keyup.enter="addTag"
                                            />
                                            <InputGroupAddon>
                                                <Button
                                                    outlined
                                                    label="Add"
                                                    @click="addTag"
                                                    :disabled="validate.$error || state.email.length == 0"
                                                />
                                            </InputGroupAddon>
                                        </div>
                                        <span class="text-sm text-red-400" v-if="validate.$error">{{ validate.email.email.$message }}</span>
                                    </div>
                                    <div class="flex gap-2 flex-wrap">
                                        <Tag v-for="(tag, i) of tags" rounded severity="secondary">
                                            <div class="flex gap-2">
                                                <span>{{ tag.email }}</span>
                                                <i class="pi pi-times text-sm cursor-pointer" @click="delTag(i)"></i>
                                            </div>
                                        </Tag>
                                    </div>
                                </InputGroup>
                            </div>
                            <div v-if="typeOfPayment == EFormOfPayment.PERSONAL">
                                <Button
                                    class="flex justify-content-start text-xs p-0"
                                    label="У меня есть промокод"
                                    severity="contrast"
                                    text
                                    @click="toggleModalPromocode"
                                />
                                <div class="flex gap-3" v-if="showModalPromocode">
                                    <IconField>
                                        <InputIcon
                                            v-if="!validPromocode && discount > 0"
                                            iconPosition="right"
                                            class="pi pi-check"
                                            style="color: green"
                                        >
                                        </InputIcon>
                                        <InputText
                                            placeholder="Промокод"
                                            v-model="promocode"
                                            :invalid="validPromocode"
                                            :class="{ 'border-green-400': !validPromocode && discount > 0 }"
                                        />
                                    </IconField>
                                    <Button label="Применить" @click="applyPromocode" outlined :disabled="promocode.length == 0"></Button>
                                </div>
                            </div>
                            <div class="flex flex-column gap-2" v-else>
                                <span class="text-left"><b>Почта для вопросов по оплате </b></span>
                                <InputText v-model="emailFeedback" class="w-full" placeholder="Введите почту для обратной связи" />
                            </div>
                            <div class="flex flex-column gap-3" v-if="costCalculation() > 0">
                                <div class="text-3xl">
                                    Итоговая стоимость: <b>{{ costCalculation() }}</b>
                                </div>
                                <Button
                                    v-if="typeOfPayment == EFormOfPayment.PERSONAL"
                                    label="Оплатить и забронировать место"
                                    @click="payCourse"
                                ></Button>
                                <Button
                                    v-else
                                    :disabled="!company || !tags || !emailFeedback"
                                    label="Скачать счет"
                                    @click="getInvoiceMail"
                                ></Button>
                            </div>
                        </div>
                    </div>
                </template>
            </Card>
        </div>
    </WrapperBlockColumn>
    <Dialog v-model:visible="popupArsenalPay" modal dismissableMask header="После оплаты вы получите письмо со всеми подробностями">
        <div id="arsenalpay-widget"></div>
    </Dialog>
</template>

<script setup lang="ts">
import { ref, reactive } from "vue";
import { formatDate } from "@/shared/lib/utils/date";
import { WrapperBlockColumn } from "@/shared/ui/marketing";
import type { IBlock } from "@/entities/Interface";
import { useInvoiceStore } from "@/entities/Invoice";
import { useInterfaceStore } from "@/entities/Interface";
import { storeToRefs } from "pinia";
import type { IPayloadCourses, ISelectedTariff } from "@/entities/Interface/model/types";
import { useVuelidate } from "@vuelidate/core";
import { email } from "@vuelidate/validators";
import { EFormOfPayment } from "@/shared/lib/types";

import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";

defineProps<{ data: IBlock }>();

const generateInvoice = useInvoiceStore();
const { suggestions } = storeToRefs(generateInvoice);
const interfaceStore = useInterfaceStore();

interface ITag {
    email: string;
}

declare const ArsenalpayWidget: any;

const typeOfPayment = ref<string>("personal");
const company = ref<any>();
const state = reactive({
    email: "",
});
const correctInputEmail = ref<boolean>(true);
const promocode = ref<string>("");
const showModalPromocode = ref<boolean>(false);
const tags = ref<ITag[]>([]);
const discount = ref<number>(0);
const selectedTariff = ref<ISelectedTariff>();
const emailFeedback = ref<string>("");
const validPromocode = ref<boolean>(false);
const popupArsenalPay = ref<boolean>(false);
const formOfPayment = ref([
    {
        value: EFormOfPayment.PERSONAL,
        text: "Картой",
    },
    {
        value: EFormOfPayment.CORPORATE,
        text: "По счету",
    },
]);
const rules = {
    email: { email },
};
const validate = useVuelidate(rules, state);

const getCompany = (event: any) => {
    generateInvoice.getCompany(event.query);
};
const optionLabelAutoComplete = (event: any) => {
    return event.value + ` (ИНН: ${event.data.inn})`;
};

function toggleModalPromocode() {
    showModalPromocode.value = !showModalPromocode.value;
}

function addTag() {
    if (correctInputEmail.value && state.email.length > 0) {
        tags.value.push({ email: state.email });
        state.email = "";
    }
}
function delTag(i: number) {
    tags.value.splice(i, 1);
}
function selectTariff(tarif: any) {
    selectedTariff.value = tarif;
}
function costCalculation() {
    if (selectedTariff.value) {
        if (typeOfPayment.value != EFormOfPayment.PERSONAL) {
            return selectedTariff.value.plan.corporate_price;
        } else {
            if (!!discount.value && discount.value != 0) {
                return (selectedTariff.value.plan.price / 100) * (100 - discount.value) * tags.value.length;
            } else {
                return selectedTariff.value.plan.price * tags.value.length;
            }
        }
    } else {
        return 0;
    }
}

async function validateInput() {
    const isCorrect = await validate.value.$validate();
    correctInputEmail.value = isCorrect;
}
async function applyPromocode() {
    const res = await interfaceStore.applyPromocode(promocode.value);
    if (res) {
        discount.value = res;
        validPromocode.value = false;
    } else {
        discount.value = 0;
        validPromocode.value = true;
    }
}
async function payCourse() {
    const payload: IPayloadCourses = {
        class: selectedTariff.value?.id,
        corporate_inn: null,
        type: typeOfPayment.value,
        email_for_invoice: null,
        total_price: costCalculation(),
        promocode: promocode.value || null,
        students: tags.value,
    };

    const submissionId = await interfaceStore.payCoursePersonal(payload);

    if (submissionId) {
        let recaptchaScript = document.createElement("script");
        recaptchaScript.src = "https://arsenalpay.ru/widget/script.js";
        document.head.appendChild(recaptchaScript);

        recaptchaScript.onload = async function () {
            let arsenal = new ArsenalpayWidget();
            arsenal.widget = 2227;
            arsenal.destination = "COURSES;" + tags.value[0].email + ";" + submissionId;
            arsenal.amount = costCalculation();
            arsenal.element = "arsenalpay-widget";
            await arsenal.render();
        };

        popupArsenalPay.value = true;
    }
}

async function getInvoiceMail() {
    if (selectedTariff.value) {
        const payload: IPayloadCourses = {
            class: selectedTariff.value?.id,
            corporate_inn: company.value.data.inn,
            type: typeOfPayment.value,
            email_for_invoice: emailFeedback.value,
            total_price: costCalculation(),
            promocode: promocode.value || null,
            students: tags.value,
        };

        interfaceStore.getInvoiceMail(payload, company.value, selectedTariff.value);
    }
}
</script>

<style scoped></style>
