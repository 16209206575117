<template>
    <Button
        icon="pi pi-palette"
        size="small"
        class="h-2rem"
        outlined
        v-tooltip.bottom="$t('change-color')"
        @click="colorChangeStore.toggleColorPicker"
    />
    <ChangeColorOverlay />
</template>

<script setup lang="ts">
import { useColorChangeStore, ChangeColorOverlay } from "../";

const colorChangeStore = useColorChangeStore();
</script>

<style scoped></style>
