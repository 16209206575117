import { useToast } from "primevue/usetoast";

// Добавить мультиязычность
export function useNotification() {
    const toast = useToast();

    const life: number = 3000;

    function showInfo(message: unknown): void {
        toast.add({
            severity: "info",
            summary: "Info",
            detail: message,
            life,
        });
    }
    function showWarning(message: unknown): void {
        toast.add({
            severity: "warn",
            summary: "Warning",
            detail: message,
            life,
        });
    }
    function showError(message: unknown): void {
        toast.add({
            severity: "error",
            summary: "Error",
            detail: message,
            life,
        });
    }
    function showSuccess(message: unknown): void {
        toast.add({
            severity: "success",
            summary: "Success",
            detail: message,
            life,
        });
    }

    return {
        showInfo,
        showSuccess,
        showWarning,
        showError,
    };
}
