import { defineStore } from "pinia";
import type { IApproval } from ".";
import { ref } from "vue";
import { ApprovalApi } from "..";
import { useLoading, useNotification } from "@/shared/lib/composables";

export const useApprovalStore = defineStore("approval", () => {
    const { isLoading, startLoading, finishLoading } = useLoading();
    const { showError } = useNotification();

    const approvals = ref<IApproval[]>([]);
    const countApprovals = ref<number>(0);

    const myApprovalByDiagram = ref<IApproval>();
    const approvalsByDiagram = ref<IApproval[]>([]);

    async function fetchAll() {
        try {
            startLoading();

            approvals.value = [];
            approvals.value = await ApprovalApi.fetchAllMy();
            countApprovals.value = approvals.value.length;
        } catch (e: any) {
            showError(e?.message | e);
        } finally {
            finishLoading();
        }
    }
    async function fetchMyByDiagramId(diagramId: string) {
        try {
            myApprovalByDiagram.value = undefined;
            const data: IApproval[] = await ApprovalApi.fetchAllMyByDiagramId(diagramId);
            myApprovalByDiagram.value = data[0];
        } catch (e: any) {
            if (e?.status !== 404) {
                showError(e?.message || e);
            }
        }
    }
    async function fetchAllByDiagramId(diagramId: string) {
        try {
            approvalsByDiagram.value = [];
            approvalsByDiagram.value = await ApprovalApi.fetchAllByDiagramId(diagramId);
        } catch (e: any) {
            if (e?.status !== 404) {
                showError(e?.message || e);
            }
        }
    }

    return {
        approvals,
        countApprovals,
        myApprovalByDiagram,
        approvalsByDiagram,
        isLoading,
        fetchAll,
        fetchMyByDiagramId,
        fetchAllByDiagramId,
    };
});
