<template>
    <Head> </Head>
</template>

<script setup lang="ts">
import { Head } from "@unhead/vue/components";
import { useInterfaceStore } from "@/entities/Interface";
import { storeToRefs } from "pinia";
import { useHead, useSeoMeta } from "@unhead/vue";

const interfaceStore = useInterfaceStore();
const { seo } = storeToRefs(interfaceStore);

useHead({
    meta: [{ charset: "UTF-8" }],

    link: [
        {
            rel: "canonical",
            href: () => seo.value.canonical_url,
            key: "canonical",
        },
    ],
    script: [
        {
            type: "application/ld+json",
            innerHTML: () => (seo.value?.json_schema ? seo.value?.json_data : "{}"),
        },
    ],
});

useSeoMeta({
    title: () => seo.value?.title,
    description: () => seo.value?.meta_desciription,
    ogDescription: () => seo.value?.og_description,
    ogTitle: () => seo.value?.og_title,
    ogImage: () => seo.value?.og_image,
    robots: () => seo.value?.robots,
    ogUrl: () => seo.value?.og_url,
    ogType: () => seo.value?.og_type,
    ogSiteName: () => seo.value?.og_sitename,
});
</script>

<style scoped></style>
