<template>
    <Menubar
        :pt="{
            start: () => ({
                class: ['w-full'],
            }),
        }"
        class="max-h-4rem"
    >
        <template #start>
            <div class="flex align-items-center justify-content-start gap-2">
                <RouterLink :to="{ name: ERoutesName.APP_MY_PROCESSES }">
                    <img src="@/shared/assets/images/svg/favicon.svg" style="height: 40px; width: auto"
                /></RouterLink>
                <slot>
                    <h1 class="white-space-nowrap">{{ headerTitle }}</h1>
                </slot>
            </div>
        </template>
        <template #end>
            <div class="min-w-max flex gap-3 align-items-center select-none">
                <span v-badge.secondary="0" class="pi pi-bell text-xl"></span>
                <div class="p-2 hover:surface-hover border-round flex gap-2 align-items-center cursor-pointer">
                    <RouterLink :to="{ name: ERoutesName.APP_MY_PROCESSES }">
                        <span>{{ $t("My-diagrams") }}</span></RouterLink
                    >
                </div>
                <div
                    class="p-2 hover:surface-hover border-round flex gap-2 align-items-center cursor-pointer"
                    :class="{ 'surface-hover': menuNewProcessRef?.overlayVisible }"
                    aria-haspopup="true"
                    aria-controls="overlay_menu_new_process"
                    @click="toggleMenuNewProcess"
                >
                    <span class="pi pi-plus"></span>
                    <span>{{ $t("new-process") }}</span>
                    <span class="pi pi-angle-down"></span>
                </div>
                <div
                    class="p-2 hover:surface-hover border-round flex gap-2 align-items-center cursor-pointer"
                    :class="{ 'surface-hover': menuProfileRef?.overlayVisible }"
                    aria-haspopup="true"
                    aria-controls="overlay_menu_profile"
                    @click="toggleMenuProfile"
                >
                    <Avatar :username="userData.username" icon="pi pi-user" shape="circle" />
                    <span>{{ userData.username }}</span>
                    <span class="pi pi-angle-down"></span>
                </div>
            </div>
            <Menu ref="menuProfileRef" id="overlay_menu_profile" :model="menuProfileItems" :popup="true">
                <template #item="{ item, props }">
                    <router-link class="flex align-items-center" :class="item?.class" v-bind="props?.action" :to="item.link || ''">
                        <span :class="item.icon" />
                        <span class="ml-2">{{ item.label }}</span>
                    </router-link>
                </template>
            </Menu>
            <Menu ref="menuNewProcessRef" id="overlay_menu_new_process" :model="menuNewProcessItems" :popup="true" />
        </template>
    </Menubar>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";

import Menubar from "primevue/menubar";

import Menu from "primevue/menu";
import { Avatar } from "@/shared/ui/avatar/";
import { PrimeIcons } from "primevue/api";

import { ERoutesName } from "@/app/providers";
import { useSessionStore } from "@/entities/Session";
import { useInterfaceStore } from "@/entities/Interface";

import { useI18n } from "vue-i18n";

const { t } = useI18n();
const route = useRoute();

const sessionStore = useSessionStore();
const interfaceStore = useInterfaceStore();
const { userData } = storeToRefs(sessionStore);
const { menuProfileItems } = storeToRefs(interfaceStore);

const headerTitle = computed(() => !route.meta.hideTitle && route.meta.title);

const menuNewProcessRef = ref();
const menuNewProcessItems = ref([
    { label: "BPMN", icon: PrimeIcons.BOX },
    { label: "PlantUML" },
    { label: t("Process-import"), icon: PrimeIcons.FILE_IMPORT },
    { label: t("use-team-template"), icon: PrimeIcons.TABLE },
    { label: t("download-from-server"), icon: PrimeIcons.SERVER },
]);
function toggleMenuNewProcess(event: Event): void {
    menuNewProcessRef.value.toggle(event);
}

const menuProfileRef = ref();

function toggleMenuProfile(event: Event): void {
    menuProfileRef.value.toggle(event);
}
</script>

<style scoped>
.priority {
    color: var(--primary-color);
}
</style>
