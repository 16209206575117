<template>
    <div class="w-full border-1 p-6 text-base text-color line-height-4" id="print-invoice">
        <div class="flex flex-column gap-5">
            <div class="flex flex-column border-1">
                <div class="flex border-bottom-1">
                    <div class="p-3 w-6 border-right-1">
                        МОСКОВСКИЙ ФИЛИАЛ АО КБ"МОДУЛЬБАНК"<br />
                        <span class="text-400">Банк получателя</span>
                    </div>
                    <div class="p-3 w-6">
                        БИК 044525092 <br />
                        Счет № 30101810645250000092
                    </div>
                </div>
                <div class="flex">
                    <div class="p-3 border-right-1 w-6">
                        Индивидуальный предприниматель <br />
                        Котов Денис Геннадьевич <br />
                        <span class="text-400"> ИНН 463312037917 <br />Получатель </span>
                    </div>
                    <div class="p-3 w-6">Счет № 40802810070010114738</div>
                </div>
            </div>
            <div class="flex flex-column">
                <h3 class="text-3xl font-medium">Счет на оплату №{{ invoiceNumber }} от {{ formatDate(new Date().getTime()) }}</h3>
                <span>
                    <span class="font-bold">Поставщик:</span> Индивидуальный предприниматель Котов Денис Геннадьевич, ИНН 463312037917, обл
                    Курская, гЖелезногорск, ул Л.Голенькова, д. 3, кв. 12, п/и 307173
                </span>
                <span> <span class="font-bold">Поставщик:</span> {{ selectedCompany }} </span>
            </div>
            <div class="flex flex-column gap-2">
                <div class="border-1">
                    <div class="flex text-center font-bold border-bottom-1 border-black-alpha-20">
                        <div class="w-9 p-2 border-right-1">Наименование</div>
                        <div class="w-3 p-2">Сумма</div>
                    </div>
                    <div class="flex">
                        <div class="w-9 border-right-1 p-2">
                            Предоставление доступу к STORMBPMN. Количество пользователей - {{ invoice.quantityParticipants || 0 }}.
                            Количество месяцев - {{ invoice.quantityMonths || 0 }}.
                        </div>
                        <div class="w-3 p-2">{{ price }} рублей.</div>
                    </div>
                </div>

                <span>Всего 1 наименование, на сумму {{ price }} рублей. Без НДС.</span>
                <span>По лицензионному соглашению https://stormbpmn.com/license .</span>
                <span>Администратор команды {{ userData.username }}</span>
            </div>
            <div class="flex justify-content-between align-items-center font-bold">
                <div class="flex align-items-center gap-5">
                    Руководитель
                    <img class="h-4rem" src="@/shared/assets/images/png/kotov-signature.webp" alt="" />
                </div>
                <div>Котов Денис Геннадьевич</div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import { storeToRefs } from "pinia";
import { useSessionStore } from "@/entities/Session";
import { useInvoiceStore } from "../model";
import type { IInvoice } from "../model";
import { formatDate } from "@/shared/lib/utils/date";
import { type ICalcPricePayload } from "../model";
import { generateInvoiceNumber } from "../lib";

const sessionStore = useSessionStore();
const invoiceStore = useInvoiceStore();
const { userData } = storeToRefs(sessionStore);
const { price } = storeToRefs(invoiceStore);
const props = defineProps<{
    invoice: IInvoice;
}>();

const invoiceNumber = computed<string>(() => {
    return generateInvoiceNumber(userData.value.teamId, props.invoice.quantityParticipants, props.invoice.quantityMonths);
});
const selectedCompany = computed<string>(() => {
    if (props.invoice.company) {
        return (
            props.invoice.company.data.name.full_with_opf +
            ", ИНН " +
            props.invoice.company.data.inn +
            ", " +
            props.invoice.company.data.address.unrestricted_value
        );
    }
    return "";
});

watch(props.invoice, async () => {
    if (
        props.invoice.quantityMonths &&
        props.invoice.quantityParticipants &&
        props.invoice.quantityMonths != 0 &&
        props.invoice.quantityParticipants != 0
    ) {
        const payload: ICalcPricePayload = {
            billingInterval: "Monthly",
            plan: "Team",
            membersCount: props.invoice?.quantityMonths,
            locale: "ru",
            monthCount: props.invoice.quantityParticipants,
        };
        await invoiceStore.calcPrice(payload);
    } else invoiceStore.resetPrice();
});
</script>

<style scoped></style>
