export enum ESeverity {
    SECONDARY = "secondary",
    SUCCESS = "success",
    INFO = "info",
    PRIMARY = "primary",
    DANGER = "danger",
    CONTRAST = "contrast",
    WARNING = "warning",
}

export enum EFormOfPayment {
    PERSONAL = "personal",
    CORPORATE = "corporate",
}
