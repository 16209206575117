<template>
    <div class="comment" :class="commentClass" v-tooltip.left="commentTooltipText">
        <slot name="edit" v-if="comment.isEdit"> </slot>
        <template v-else>
            <slot name="position"></slot>
            <div class="comment-header">
                <b class="flex-auto">{{ comment.author.you ? "Вы" : comment.author.name }}</b>
                <div class="flex">
                    <slot name="actions" v-if="comment.author.you && !comment.done"></slot>
                    <slot name="completed"></slot>
                </div>
            </div>
            <div class="comment-header">
                <span class="comment-title inline-block">
                    <span class="comment__time">{{ timeAgo(comment.date) }}</span>
                </span>
            </div>
            <div class="mt-2">
                <span>{{ comment.text }}</span>
            </div>
            <div v-if="comment.files.length" class="mt-2 file-list">
                <span
                    class="item"
                    v-for="(item, index) of comment.files"
                    @click.stop="onOpenGalleria(comment.files, index)"
                    v-tooltip.top="item.urlPreview ? '' : item.name"
                >
                    <ImageStorm v-if="item.urlPreview" :src="setFileUrl(item.urlPreview)" :alt="item.name" />
                    <div class="extension flex flex-column align-items-center gap-1" v-else>
                        <div v-if="countAttachments < 4" class="pi pi-file" :class="iconSizeClass"></div>
                        <b :class="textSizeClass">{{ item.name.split(".").at(-1) }}</b>
                    </div>
                </span>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { timeAgo } from "@/shared/lib/utils/date";
import { setFileUrl } from "@/shared/lib/utils/file";
import { useGalleria } from "@/shared/lib/composables";
import { ImageStorm } from "@/shared/ui/image";

import { type IComment, type ICommentFile } from "@/entities/Comment";
import { downloadFile } from "@/shared/lib/browser";

const { setGalleriaCustomIndex, setGalleriaFiles, toggleGalleriaVisible } = useGalleria();

interface IProps {
    comment: IComment;
}

const props = defineProps<IProps>();

const commentClass = computed<string>(() => {
    if (!props.comment.readed) return "comment_unreaded";
    if (props.comment.done) return "comment_done";
    if (props.comment.important) return "comment_important";
    return "";
});
const commentTooltipText = computed<string>(() => {
    if (!props.comment.readed) return "Непрочитано";
    if (props.comment.done) return "Выполнено";
    if (props.comment.important) return "Очень важно";
    return "";
});
const countAttachmentsData = computed<{ filesCount: number; imageCount: number; count: number }>(() => {
    let filesCount = 0;
    let imageCount = 0;

    for (const file of props.comment.files) {
        if (file.urlPreview) {
            imageCount++;
        } else {
            filesCount++;
        }
    }

    return {
        filesCount,
        imageCount,
        count: props.comment.files.length || 0,
    };
});
const countAttachments = computed<number>(() => countAttachmentsData.value.count);
const heightOneFile = computed<string>(() => {
    const MIN = 21;
    const MAX = 142;

    if (countAttachmentsData.value.imageCount > 0) return `45px`;

    const size = (MAX - MIN) / countAttachmentsData.value.filesCount;

    return `${MIN + size}px`;
});
const iconSizeClass = computed<string>(() => {
    const size = countAttachments.value;

    return size === 3 ? "text-2xl" : size === 1 ? "text-6xl" : "text-4xl";
});
const textSizeClass = computed<string>(() => {
    const size = countAttachments.value;
    if (size === 2) return "text-base";
    if (size === 1) return "text-xl";
    return "text-sm";
});

function onOpenGalleria(files: ICommentFile[], index: number) {
    const file = files[index];
    if (!file.urlPreview) {
        return downloadFile(file.url, file.name);
    }

    let visibleIndex = 0;

    setGalleriaFiles(
        files.map((item, indexLocal) => {
            if (indexLocal < index && item.urlPreview) {
                visibleIndex++;
            }

            return {
                url: setFileUrl(item.url),
                preview: item.urlPreview ? setFileUrl(item.urlPreview) : "",
                extension: item.name.split(".").at(-1) || "",
            };
        }),
    );

    setGalleriaCustomIndex(visibleIndex);
    toggleGalleriaVisible();
}
</script>

<style lang="scss" scoped>
@import "primeflex/primeflex.scss";

.comment {
    cursor: default;
    @include styleclass("px-3 py-2");

    &-header {
        @include styleclass("flex gap-2 align-items-center");
    }
    &__time {
        @include styleclass("opacity-60");
    }

    &_important {
        @include styleclass("bg-orange-50 text-orange-500 border-left-2 border-orange-200");

        :deep(.button) {
            @include styleclass("text-orange-500");

            &:hover {
                @include styleclass("bg-orange-100");
            }
        }
    }
    &_done {
        @include styleclass("surface-50 text-300");

        img {
            @include styleclass("opacity-40");
        }

        :deep(.button) {
            @include styleclass("text-green-500");

            &:hover {
                @include styleclass("bg-green-100");
            }
        }
    }
    &_unreaded {
        @include styleclass("bg-gray-100");
    }
}

.comment-title {
    width: 87%;
}

.file-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(v-bind(countAttachments), 1fr);
    @include styleclass("gap-1");

    .item {
        position: relative;
        width: 100%;
        height: auto;
        min-height: v-bind(heightOneFile);
        @include styleclass("border-round overflow-hidden border-1 border-200 cursor-pointer bg-white");

        &:last-child {
            margin: 0px;
        }

        &-file-cover {
            @include styleclass("absolute top-0 left-0 w-full h-full text-2xl font-bold select-none");

            .number {
                position: absolute;
                top: 50%;
                left: 50%;
                translate: -50% -50%;
                cursor: inherit;
                color: white;
                z-index: 2;
            }
        }
    }
}

.extension {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
}
</style>
