<template>
    <Button
        :icon="iconButton"
        size="small"
        class="h-2rem"
        :outlined="!isCheckErrors"
        v-tooltip.bottom="textButton"
        @click="actionMenuStore.toggleCheckErrors"
    />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

import { useDiagramActionMenuStore } from "@/entities/Process";

const actionMenuStore = useDiagramActionMenuStore();

const { isCheckErrors } = storeToRefs(actionMenuStore);
const { t } = useI18n();

const iconButton = computed(() => (isCheckErrors.value ? "pi pi-times-circle" : "pi pi-check-circle"));
const textButton = computed(() => (isCheckErrors.value ? t("disable-error-check") : t("check-error")));
</script>

<style scoped></style>
