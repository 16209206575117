import { ref, watch } from "vue";
import { defineStore } from "pinia";

import { EProcessView } from "./types";
import { useLocalStorage } from "@/shared/lib/browser";
import { PROCESS_VIEW_KEY } from "@/shared/config";

export const useProcessView = defineStore("process-change-view", () => {
    const { value, setLSValue } = useLocalStorage(PROCESS_VIEW_KEY);

    const view = ref<EProcessView>((value as EProcessView) || EProcessView.TABLE);

    const viewList = ref([
        { value: EProcessView.TABLE, icon: "pi-table" },
        { value: EProcessView.GRID, icon: "pi-th-large" },
    ]);

    watch(view, () => {
        setLSValue(view.value);
    });

    return {
        view,
        viewList,
    };
});
