<template>
    <div class="w-full h-screen flex flex-column">
        <MainHeader class="top-0 left-0 right-0" />
        <main class="w-full flex-auto mt-7">
            <slot>
                <RouterView></RouterView>
            </slot>
        </main>
        <Footer />
    </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { MainHeader } from "@/widgets/header";
import { Footer } from "@/widgets/footer";
import { useInterfaceStore } from "@/entities/Interface";
import { useRoute, useRouter, onBeforeRouteUpdate, type NavigationGuardNext } from "vue-router";
import { ERoutesName } from "@/app/providers";

const interfaceStore = useInterfaceStore();
const route = useRoute();
const router = useRouter();

onMounted(async () => {
    interfaceStore.getHeader();
    if (route.name == ERoutesName.MARKETING) {
        const res = await interfaceStore.fetchAll(route.fullPath);
        if (!res) router.replace({ name: ERoutesName.NOT_FOUND });
    }
    interfaceStore.getFooter();
});

onBeforeRouteUpdate(async (to: any, from: any, next: NavigationGuardNext) => {
    if (to.name == ERoutesName.MARKETING) {
        const res = await interfaceStore.fetchAll(to.fullPath);
        if (res) next();
        else next({ name: ERoutesName.NOT_FOUND });
    } else next();
});
</script>
