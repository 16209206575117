import { ref } from "vue";
import { defineStore } from "pinia";

export const useDiagramActionMenuStore = defineStore("diagram-action-menu", () => {
    const isFullscreen = ref<boolean>(false);
    const isCamundaPanel = ref<boolean>(false);
    const isMinimap = ref<boolean>(false);
    const isCheckErrors = ref<boolean>(false);

    function toggleCamundaPanel() {
        isCamundaPanel.value = !isCamundaPanel.value;
    }
    function toggleMinimap() {
        document.querySelector(".djs-minimap")?.classList.toggle("hidden");
        isMinimap.value = !isMinimap.value;
    }
    function toggleFullscreen() {
        if (document.fullscreenElement) {
            isFullscreen.value = false;
            document.querySelector(".canvas")?.classList.remove("canvas_fullscreen");
            document.querySelector(".p-menubar")?.classList.remove("hidden");

            const element = document.querySelector(".diagram-container");
            if (element) {
                element.classList.remove("h-screen");
                element.classList.add("content");
            }

            return document.exitFullscreen();
        }
        document.body.requestFullscreen();
        document.querySelector(".canvas")?.classList.add("canvas_fullscreen");
        document.querySelector(".p-menubar")?.classList.add("hidden");

        const element = document.querySelector(".diagram-container");
        if (element) {
            element.classList.add("h-screen");
            element.classList.remove("content");
        }

        isFullscreen.value = true;
    }
    function toggleCheckErrors() {
        isCheckErrors.value = !isCheckErrors.value;
    }

    function resetMenu() {
        isFullscreen.value = false;
        isCamundaPanel.value = false;
        isMinimap.value = false;
        isCheckErrors.value = false;
    }

    return {
        isFullscreen,
        isCamundaPanel,
        isMinimap,
        isCheckErrors,
        toggleCamundaPanel,
        toggleMinimap,
        toggleFullscreen,
        toggleCheckErrors,
        resetMenu,
    };
});
