import { TOKEN_KEY, API_URL } from "@/shared/config";
import { useLocalStorage } from "@/shared/lib/browser";
import { useSessionStore } from "@/entities/Session";

import axios, { type AxiosError, type AxiosResponse } from "axios";
import { setupCache } from "axios-cache-interceptor";

const instance = axios.create({
    baseURL: API_URL,
});
instance.interceptors.request.use(
    config => {
        const token = useLocalStorage(TOKEN_KEY).value;
        if (!!token) {
            config.headers.Authorization = "Bearer " + token;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

// Размещено здесь, так как нужно чтобы интерцепторы правильно работали
const httpClient = setupCache(instance, {
    debug(msg) {
        console.log(msg);
    },
    ttl: 1000 * 30,
});

instance.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    (error: AxiosError) => {
        const { response } = error;

        if (response?.status === 401) {
            const storeSession = useSessionStore();
            storeSession.logout();
        }

        if (response?.data) {
            const oMessage = typeof response.data === "string" ? { message: response.data } : { ...response.data };
            throw { status: response.status, ...oMessage };
        }

        return Promise.reject(error);
    },
);

export { httpClient };
