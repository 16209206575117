import { useLoading, useNotification } from "@/shared/lib/composables";
import { useCommentStore, type IComment, CommentApi } from "@/entities/Comment";
import { useDiagramStore } from "@/entities/Process";
import { storeToRefs } from "pinia";

export function useCommentToggleCompleted() {
    const { isLoading, startLoading, finishLoading } = useLoading();
    const { showError } = useNotification();

    const commentStore = useCommentStore();
    const diagramStore = useDiagramStore();

    const { diagram } = storeToRefs(diagramStore);

    async function toggleCompleted(comment: IComment) {
        try {
            startLoading();

            if (diagram.value?.id) {
                await CommentApi.toggleCompleted(comment.id, diagram.value.id);

                comment.done = !comment.done;

                commentStore.replaceComment(comment);
            }
        } catch (e) {
            showError(e);
        } finally {
            finishLoading();
        }
    }

    return {
        isLoading,
        toggleCompleted,
    };
}
