<template>
    <Button
        :icon="iconFullScreen"
        size="small"
        class="h-2rem"
        :outlined="!isFullscreen"
        v-tooltip.bottom="$t('fullscreen')"
        @click="diagramActionMenuStore.toggleFullscreen"
    />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";

import { useDiagramActionMenuStore } from "@/entities/Process";

const diagramActionMenuStore = useDiagramActionMenuStore();
const { isFullscreen } = storeToRefs(diagramActionMenuStore);

const iconFullScreen = computed(() => {
    if (isFullscreen.value) return "pi pi-arrow-down-left-and-arrow-up-right-to-center";
    return "pi pi-arrow-up-right-and-arrow-down-left-from-center";
});
</script>

<style scoped></style>
