<template>
    <WrapperBlockColumn>
        <div class="mt-5">
            <div v-html="data?.item?.headline"></div>
            <div v-html="data?.item?.content"></div>
        </div>
        <IconField iconPosition="left" class="p-float-label p-input-icon card w-full" v-if="data?.item?.search">
            <InputText v-model="nameElement" :useGrouping="false" id="adminUserName" class="w-full" />
            <label>Введите элемент</label>
            <InputIcon class="pi pi-times cursor-pointer" @click="clearSearch"></InputIcon>
        </IconField>

        <div class="grid w-full gap-3">
            <div class="flex justify-content-start w-full p-0">Элементов : {{ elements?.length }}</div>
            <div class="hidden md:block col-3 p-0" v-if="data?.item?.sidebar_groupping">
                <div class="flex flex-column gap-2 border-1 border-black-alpha-20 border-round-lg p-3">
                    <template v-for="(name, index) of baseClass">
                        <Button
                            class="flex justify-content-between text-600 border-none shadow-none"
                            @click="selectGroup(name.name)"
                            :class="[name.name == selectedGroup ? 'bg-indigo-100 text-indigo-500' : 'bg-white hover:surface-100']"
                        >
                            <h2>{{ name.name }}</h2>
                            <span class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center">{{
                                name.count
                            }}</span>
                        </Button>
                        <Divider v-if="index < baseClass.length - 1" class="m-0 border-black-alpha-20" />
                    </template>
                </div>
            </div>
            <div class="col flex flex-column gap-3 p-0">
                <Element v-for="item of elements" :element="item" :key="item.id" />
            </div>
        </div>
    </WrapperBlockColumn>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { WrapperBlockColumn, Element } from "@/shared/ui/marketing";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import type { IBlock } from "@/entities/Interface";

const props = defineProps<{ data: IBlock }>();

const nameElement = ref<string>("");
const selectedGroup = ref<string>("");
const elements = computed(() => {
    if (props.data.item.elements) {
        props.data.item.elements.sort((a, b) => a.Bpmn_elements_id.base_class.localeCompare(b.Bpmn_elements_id.base_class));
        if (!!nameElement.value) {
            selectedGroup.value = "";
            return props.data.item.elements.filter(
                (item: any) =>
                    item.Bpmn_elements_id.name.toLowerCase().includes(nameElement.value.toLowerCase()) ||
                    item.Bpmn_elements_id.base_class.toLowerCase().includes(nameElement.value.toLowerCase()) ||
                    item.Bpmn_elements_id.original_name.toLowerCase().includes(nameElement.value.toLowerCase()) ||
                    item.Bpmn_elements_id.description.toLowerCase().includes(nameElement.value.toLowerCase()),
            );
        } else if (selectedGroup.value) {
            return props.data.item.elements.filter((item: any) => item.Bpmn_elements_id.base_class == selectedGroup.value);
        }
        return props.data.item.elements;
    }
});

const baseClass = computed(() => {
    const nameClass: any = [];
    if (props.data.item.elements) {
        props.data.item.elements.map((item: any) => {
            const filterName = nameClass.find((name: any) => name.name == item.Bpmn_elements_id.base_class);
            if (!filterName) nameClass.push({ name: item.Bpmn_elements_id.base_class, count: 1 });
            else {
                filterName.count += 1;
            }
        });
    }
    return nameClass;
});

function clearSearch() {
    nameElement.value = "";
}

function selectGroup(nameGroup: string) {
    selectedGroup.value = nameGroup;
    clearSearch();
}
</script>

<style scoped></style>
