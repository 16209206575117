import { ref } from "vue";
import { storeToRefs } from "pinia";

import { useLoading, useNotification } from "@/shared/lib/composables";

import { useCommentStore, type IComment } from "@/entities/Comment";
import { useDiagramStore } from "@/entities/Process";
import { EOverlayType, useBpmnModelerOverlayStore } from "@/entities/BpmnModeler";

export function useSendComment() {
    const { isLoading, startLoading, finishLoading } = useLoading();
    const { showError } = useNotification();

    const commentStore = useCommentStore();
    const diagramStore = useDiagramStore();
    const bpmnOverlaysStore = useBpmnModelerOverlayStore();

    const { diagram } = storeToRefs(diagramStore);
    const { comments } = storeToRefs(commentStore);

    const commentModel = ref<string>("");
    const files = ref<File[]>([]);

    async function sendComment(elementId: null | string) {
        try {
            startLoading();

            if (diagram.value) {
                await commentStore.createComment(
                    {
                        diagramId: diagram.value.id,
                        elementId: elementId,
                        elementName: null,
                        parent: null,
                        text: commentModel.value,
                    },
                    files.value,
                );

                commentModel.value = "";
                files.value = [];

                if (elementId) {
                    const countData = commentStore.getCountComments(comments.value, elementId);
                    if (countData.count > 1) {
                        bpmnOverlaysStore.chnageDataCommentByElement(elementId, countData);
                    }
                }
            }
        } catch (e) {
            showError(e);
        } finally {
            finishLoading();
        }
    }

    return {
        isLoading,

        commentModel,
        files,

        sendComment,
    };
}
