import { ref, reactive } from "vue";
import { defineStore } from "pinia";
import { type IUserData, UserDataDto } from "..";
import { useLocalStorage } from "@/shared/lib/browser";
import { TOKEN_KEY, USER_DATA_KEY } from "@/shared/config";
import { computed } from "vue";
import * as Sentry from "@sentry/vue";

import { useRouter } from "vue-router";
import { ERoutesName } from "@/app/providers";

export const useSessionStore = defineStore("session", () => {
    const router = useRouter();

    const { value: tokenValue, setLSValue: setLSToken } = useLocalStorage(TOKEN_KEY);
    const { value: userDataValue, setLSValue: setLSUserData } = useLocalStorage(USER_DATA_KEY);

    const token = ref(tokenValue);
    const userData = ref<IUserData>(userDataValue as IUserData);

    const isAuth = computed(() => !!token.value);
    const isFullAccess = computed(() => userData.value?.fullAccess || false);
    const isInTeam = computed(() => userData.value?.inTeam || false);

    function setToken(value: string) {
        setLSToken(value);
        token.value = value;
    }
    function setUserData(value: IUserData) {
        setLSUserData(value);
        userData.value = value;
        Sentry.setUser({ email: userData.value.username });
    }

    function login(payload: IUserData): void {
        setToken(payload.accessToken);
        setUserData(payload);

        router.push({
            name: ERoutesName.APP_MY_PROCESSES,
        });
    }
    function logout(): void {
        setToken("");
        setUserData({ ...new UserDataDto({}) });

        /* router.push({
            name: ERoutesName.HOME,
        }); */
    }

    return {
        isAuth,
        isFullAccess,
        isInTeam,
        token,
        userData,
        login,
        logout,
    };
});
