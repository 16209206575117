<template>
    <Dropdown
        v-model="status"
        :options="processStatuses"
        showClear
        filter
        filterPlaceholder="Поиск"
        placeholder="Не выбрано"
        option-label="label"
        option-value="value"
    />
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";

import Dropdown from "primevue/dropdown";

import { useProcessStore } from "@/entities/Process/model";

const processStore = useProcessStore();
const { processStatuses } = storeToRefs(processStore);

const status = defineModel();
</script>

<style scoped></style>
