<template>
    <Button
        icon="pi pi-cog"
        size="small"
        class="h-2rem"
        :outlined="!isCamundaPanel"
        v-tooltip.bottom="$t('turn-on-camunda-panel')"
        @click="diagramActionMenuStore.toggleCamundaPanel"
    />
</template>

<script setup lang="ts">
import { useDiagramActionMenuStore } from "@/entities/Process";
import { storeToRefs } from "pinia";

const diagramActionMenuStore = useDiagramActionMenuStore();
const { isCamundaPanel } = storeToRefs(diagramActionMenuStore);
</script>

<style scoped></style>
