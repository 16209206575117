import { storeToRefs } from "pinia";

import { useBpmnModelerStore, useBpmnModelerOverlayStore, EOverlayType } from "@/entities/BpmnModeler";
import { useTeamStore } from "@/entities/Team";
import { useDiagramActionMenuStore } from "@/entities/Process";

import { useScoringStore } from "@/features/Diagram-editor/scoring";

export function useLinterOverlay() {
    const bpmnModelerStore = useBpmnModelerStore();
    const bpmnModelerOverlayStore = useBpmnModelerOverlayStore();
    const scoringStore = useScoringStore();
    const teamStore = useTeamStore();
    const actionMenuStore = useDiagramActionMenuStore();

    const { lintResultByElementIds } = storeToRefs(scoringStore);
    const { scoringSettings } = storeToRefs(teamStore);
    const { isCheckErrors } = storeToRefs(actionMenuStore);

    async function linterErrors(preClear: boolean = false): Promise<void> {
        if (preClear) {
            bpmnModelerOverlayStore.deleteOverlaysBy(undefined, EOverlayType.LINTER);
        }

        if (!isCheckErrors.value) return;

        const xml: string = await bpmnModelerStore.saveXML();
        await scoringStore.calculateScore(xml, scoringSettings.value);

        for (const element of lintResultByElementIds.value) {
            for (const lint of element.lintElements) {
                bpmnModelerOverlayStore.addOverlayData({
                    id: element.id,
                    item: {
                        type: EOverlayType.LINTER,
                        settings: {
                            open: false,
                        },
                        position: {
                            bottom: -5,
                            left: 0,
                        },
                        data: [lint],
                    },
                });
            }
        }
    }

    return {
        linterErrors,
    };
}
