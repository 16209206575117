export enum EOverlayType {
    DRILL_DOWN = "drilldown",
    LINTER = "linter",
    MESSAGE = "message",
    CREATE_COMMENT = "create-comment",
}

export interface IOverlayPosition {
    bottom?: number;
    left?: number;
    top?: number;
    right?: number;
}

export interface IAddOverlayParams {
    type: EOverlayType;
    position?: IOverlayPosition;
    scale?: boolean;
    html: HTMLElement;
}
