import { ref, watch, type VNodeRef } from "vue";
import { defineStore, storeToRefs } from "pinia";

import { type IAddOverlayParams, useBpmnModelerStore, EOverlayType, type IOverlayPosition } from "..";

interface IOverlayDataItem {
    type: EOverlayType;
    position?: IOverlayPosition;
    data?: any;
    settings: {
        open: boolean;
    };
}

interface IOverlayData {
    id: string;
    items: IOverlayDataItem[];
}
interface IOverlayDataPayload {
    id: string;
    item: IOverlayDataItem;
}

export const useBpmnModelerOverlayStore = defineStore("bpmn-modeler-overlay", () => {
    const bmpmModelerStore = useBpmnModelerStore();

    const { modelerOverlays } = storeToRefs(bmpmModelerStore);

    const overlaysData = ref<IOverlayData[]>([]);

    function addOverlayData(params: IOverlayDataPayload) {
        const overlayData = overlaysData.value.find(item => item.id === params.id);
        if (overlayData) {
            const overlayDataItem: IOverlayDataItem | undefined = overlayData.items.find(item => item.type === params.item.type);
            if (overlayDataItem) {
                overlayDataItem.data.push(...params.item.data);
            } else {
                overlayData.items.push(params.item);
            }
        } else {
            overlaysData.value.push({
                id: params.id,
                items: [params.item],
            });
        }
    }
    function deleteOverlayData(id?: string, type?: EOverlayType) {
        if (id) {
            const index = overlaysData.value.findIndex(item => item.id === id);
            if (index >= 0) {
                if (type) {
                    overlaysData.value[index].items = overlaysData.value[index].items.filter(item => item.type !== type);
                }

                if (overlaysData.value[index].items.length === 1) {
                    overlaysData.value.splice(index, 1);
                }
            }
            return;
        }

        if (type) {
            for (let i = 0; i < overlaysData.value.length; i++) {
                overlaysData.value[i].items = overlaysData.value[i].items.filter(item => item.type !== type);
                if (overlaysData.value[i].items.length === 0) {
                    overlaysData.value.splice(i, 1);
                    i--;
                }
            }
        }
    }
    function findOverlayDataItemBy(elementId: string, type: EOverlayType): undefined | IOverlayDataItem {
        const overlayData = overlaysData.value.find(item => item.id === elementId);
        if (overlayData) {
            const overlayDataItem = overlayData.items.find(item => item.type === EOverlayType.MESSAGE);
            return overlayDataItem;
        }
        return undefined;
    }
    function clearOverlayData() {
        overlaysData.value = [];
    }

    function changeStatusCommentByElement(elementId: string, status: boolean) {
        const overlayDataItem = findOverlayDataItemBy(elementId, EOverlayType.MESSAGE);
        if (overlayDataItem) {
            overlayDataItem.settings.open = status;
        }
    }
    function resetStatusCommentByAll() {
        for (const element of overlaysData.value) {
            for (const item of element.items) {
                if (item.type === EOverlayType.MESSAGE) {
                    item.settings.open = false;
                }
            }
        }
    }
    function chnageDataCommentByElement(elementId: string, data: any) {
        const overlayDataItem = findOverlayDataItemBy(elementId, EOverlayType.MESSAGE);
        if (overlayDataItem) {
            overlayDataItem.data = data;
        } else {
            addOverlayData({
                id: elementId,
                item: {
                    type: EOverlayType.MESSAGE,
                    settings: {
                        open: false,
                    },
                    data,
                },
            });
        }
    }

    function createOverlayComment(element: any) {
        const comments = findOverlays(element.id, EOverlayType.MESSAGE);

        if (comments.length) {
            changeStatusCommentByElement(element.id, true);
        } else {
            addOverlayData({
                id: element.id,
                item: {
                    type: EOverlayType.CREATE_COMMENT,
                    position: {
                        top: 0,
                        right: -7,
                    },
                    settings: {
                        open: false,
                    },
                },
            });
        }
    }

    function isExistOverlay(elementId: string, type: EOverlayType): boolean {
        const overlays: any[] = modelerOverlays.value.get({ element: elementId, type });

        return overlays.length > 0;
    }
    function findOverlays(elementId: string, type?: EOverlayType) {
        if (type) {
            return modelerOverlays.value.get({ element: elementId, type });
        }

        return modelerOverlays.value.get({ element: elementId });
    }

    function addOverlay(elementId: string, params: IAddOverlayParams) {
        const isExist = isExistOverlay(elementId, params.type);

        if (!isExist) {
            modelerOverlays.value.add(elementId, params.type, { ...params });
        }
    }
    async function deleteOverlaysBy(elementId?: string, type?: EOverlayType, isDeleteOverlayData: boolean = true): Promise<void> {
        if (elementId && type) {
            const isExist = isExistOverlay(elementId, type);
            if (!isExist) return;

            await modelerOverlays.value.remove({ element: elementId, type });
            return isDeleteOverlayData ? deleteOverlayData(elementId, type) : undefined;
        }

        if (elementId) {
            await modelerOverlays.value.remove(elementId);
            return isDeleteOverlayData ? deleteOverlayData(elementId) : undefined;
        }
        if (type) {
            await modelerOverlays.value.remove({ type });
            return isDeleteOverlayData ? deleteOverlayData(undefined, type) : undefined;
        }
    }
    function clearOverlays() {
        modelerOverlays.value.clear();
    }

    return {
        overlaysData,
        addOverlayData,
        clearOverlayData,
        resetStatusCommentByAll,

        addOverlay,
        deleteOverlaysBy,
        clearOverlays,

        createOverlayComment,
        changeStatusCommentByElement,
        chnageDataCommentByElement,
    };
});
