<template>
    <div class="flex flex-column gap-1 align-items-start">
        <Skeleton width="22rem" height="1.3rem" class="surface-300"></Skeleton>
        <div class="flex gap-2 text-sm align-items-center">
            <div class="flex gap-1 align-items-center">
                <Skeleton width="1.3rem" height="1.3rem" class="surface-300"></Skeleton>
                <Skeleton width="5rem" height="1.1rem" class="surface-300"></Skeleton>
            </div>
            <Skeleton width="3rem" height="1.1rem" class="surface-300"></Skeleton>
            <Skeleton width="7rem" height="1.1rem" class="surface-300"></Skeleton>
        </div>
        <div class="flex gap-3 align-items-center mt-1">
            <div class="flex gap-2">
                <Skeleton width="3rem" height="1.9rem" class="surface-300"></Skeleton>
                <Skeleton width="6rem" height="1.9rem" class="surface-300"></Skeleton>
                <Skeleton width="6rem" height="1.9rem" class="surface-300"></Skeleton>
                <Skeleton width="6rem" height="1.9rem" class="surface-300"></Skeleton>
                <Skeleton width="3rem" height="1.9rem" class="surface-300"></Skeleton>
            </div>
            <Skeleton width="4rem" height="1.3rem" class="surface-300"></Skeleton>
        </div>
    </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
