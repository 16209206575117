<template>
    <Dialog v-model:visible="visible" header="Измненение статуса" :draggable="false" modal :style="{ width: '35vw' }" position="top">
        <div class="pt-3 flex justify-content-start">
            <SelectButton
                v-model="selectedStatus"
                :options="processStatuses"
                optionLabel="label"
                optionValue="value"
                optionDisabled="disabled"
            />
        </div>
        <template v-if="isApprovalSelected">
            <Divider />
            <CreateApproval v-if="diagramId" :diagram-id="diagramId" />
            <Divider v-if="!!myApprovalByDiagram" />
            <SetDecision :diagram-id="diagramId" />
            <Divider />
            <ApprovalList :diagram-id="diagramId" @delete-approval="deleteApproval" />
        </template>
        <template #footer>
            <Button label="Отмена" outlined @click="hide" />
            <Button label="Установить статус" :disabled="!selectedStatus" :loading="isLoading" @click="changeStatusByDiagram" />
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";

import Dialog from "primevue/dialog";
import SelectButton from "primevue/selectbutton";

import { useDialog } from "@/shared/lib/composables";
import { EProcessStatus, useProcessStore } from "@/entities/Process/model";
import { ApprovalList, ApprovalModel } from "@/entities/Approval";

import { CreateApproval } from "@/features/Approval/create";
import { SetDecision } from "@/features/Approval/set-decision";
import { useDeleteApproval } from "@/features/Approval/delete";

import { useChangeStatus } from "..";

const { visible, show, hide } = useDialog();
const { deleteApproval } = useDeleteApproval();

const { isLoading, changeStatus } = useChangeStatus();

const processStore = useProcessStore();
const approvalStore = ApprovalModel.useApprovalStore();

const { processStatuses } = storeToRefs(processStore);
const { myApprovalByDiagram } = storeToRefs(approvalStore);

const diagramId = ref<string>();
const selectedStatus = ref<EProcessStatus>();

const isApprovalSelected = computed(() => {
    return selectedStatus.value === EProcessStatus.APPROVAL;
});

async function changeStatusByDiagram() {
    if (diagramId.value && selectedStatus.value) {
        await changeStatus(diagramId.value, selectedStatus.value);
        hide();
    }
}

function beforeShow(id: string) {
    selectedStatus.value = undefined;
    diagramId.value = id;

    show();
}

defineExpose({
    show: (diagramId: string) => beforeShow(diagramId),
});
</script>

<style scoped></style>
