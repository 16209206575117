import { ref } from "vue";
import { PrimeIcons } from "primevue/api";
import { defineStore } from "pinia";
import { useI18n } from "vue-i18n";
import { DOWNLOAD_NAME, IMG_DIRECTUS } from "@/shared/config";
import { formatDate } from "@/shared/lib/utils/date";
import { DEFAULT_SEO_CONFIG } from "@/shared/config/seo";
import { httpDirectus } from "@/shared/api";
import { useRouter } from "vue-router";
import { ERoutesName } from "@/app/providers";

import { useNotification } from "@/shared/lib/composables";
import type {
    IDirectusResponse,
    IDirectusNavigation,
    IDirectusNavigationItem,
    IResponsePayCourse,
    IResponsePromocode,
} from "@/shared/lib/types";
import type { IPayloadCourses, ISelectedTariff, ISeo } from "./types";

import { InterfaceApi } from "../api";

import { useSessionStore } from "@/entities/Session";
import { useInvoiceStore } from "@/entities/Invoice";

export const useInterfaceStore = defineStore("interfaceStore", () => {
    const { showError, showSuccess } = useNotification();
    const { t } = useI18n();
    const sessionStore = useSessionStore();
    const invoiceStore = useInvoiceStore();
    const router = useRouter();

    const pageData = ref();
    const seo = ref<ISeo>({
        title: "",
        meta_desciription: "",
        robots: "",
        og_image: "",
        og_title: "",
        og_description: "",
        og_url: "",
        og_type: "",
        canonical_url: "",
        og_sitename: "",
        json_schema: false,
        json_data: null,
    });
    const headerItem = ref<IDirectusNavigationItem[]>([]);
    const footerItem = ref<IDirectusNavigationItem[]>([]);
    const footerCopyright = ref<null | string>(null);
    const menuProfileItems = ref([
        {
            label: t("Admin"),
            icon: "pi pi-cog",
            link: "/app/admin",
        },
        {
            separator: true,
        },
        {
            label: t("Team"),
            icon: PrimeIcons.USERS,
        },
        {
            label: t("Guests"),
            icon: PrimeIcons.REPLY,
        },
        {
            separator: true,
        },
        {
            label: t("assets"),
            icon: PrimeIcons.SITEMAP,
        },
        {
            label: t("orgchart"),
            icon: PrimeIcons.BUILDING,
        },
        {
            label: t("persons"),
            icon: PrimeIcons.USERS,
        },
        {
            label: t("assignees"),
            icon: PrimeIcons.ID_CARD,
        },
        {
            separator: true,
        },
        {
            label: t("Servers"),
            icon: PrimeIcons.SERVER,
        },
        {
            label: t("camunda-manage"),
            icon: PrimeIcons.DATABASE,
        },
        {
            separator: true,
        },
        {
            label: t("Profile"),
            icon: PrimeIcons.USER,
        },
        {
            separator: true,
        },
        {
            label: t("Billing"),
            icon: PrimeIcons.WALLET,
        },
        {
            label: t("Upgrade"),
            icon: PrimeIcons.STAR_FILL,
            class: "priority",
        },
        {
            separator: true,
        },
        {
            label: t("Help"),
            icon: PrimeIcons.QUESTION_CIRCLE,
        },

        {
            label: t("Logout"),
            icon: PrimeIcons.SIGN_OUT,
            command: () => sessionStore.logout(),
        },
    ]);

    function saveSeo(data: any) {
        seo.value.title = data.title ? data.title : DEFAULT_SEO_CONFIG.title;
        seo.value.meta_desciription = data.meta_desciription ? data.meta_desciription : DEFAULT_SEO_CONFIG.meta_desciription;
        seo.value.og_description = data.og_description ? data.og_description : DEFAULT_SEO_CONFIG.og_description;
        seo.value.og_title = data.og_title ? data.og_title : DEFAULT_SEO_CONFIG.og_title;
        seo.value.og_url = data.og_url ? data.og_url : data.canonical_url;
        seo.value.og_type = data.og_type ? data.og_type : DEFAULT_SEO_CONFIG.og_type;
        seo.value.canonical_url = data.canonical_url ? data.canonical_url : DEFAULT_SEO_CONFIG.canonical_url;
        seo.value.og_sitename = data.og_sitename ? data.og_sitename : DEFAULT_SEO_CONFIG.og_sitename;

        seo.value.robots = "all";

        seo.value.json_schema = data.json_schema ? data.json_schema : null;

        seo.value.json_data = data.json_data ? JSON.stringify(data.json_data) : null;

        if (data.og_image) {
            let ogImg = data.og_image;
            ogImg.id = IMG_DIRECTUS + ogImg.id;
            seo.value.og_image = ogImg.id;
        } else seo.value.og_image = DEFAULT_SEO_CONFIG.og_image;
    }

    async function getHeader(): Promise<void> {
        try {
            const res: IDirectusResponse<IDirectusNavigation> = await InterfaceApi.getHeader();
            res.data.items[res.data.items.length - 1].last = true;
            headerItem.value = res.data.items;
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function getFooter(): Promise<void> {
        try {
            const res: IDirectusResponse<IDirectusNavigation> = await InterfaceApi.getFooter();
            footerItem.value = res.data.items;
            footerCopyright.value = res.data.copyright;
            console.log(footerItem.value);
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function applyPromocode(promocode: string) {
        try {
            const res: IDirectusResponse<IResponsePromocode[]> = await InterfaceApi.applyPromocode(promocode);
            if (res.data.length > 0) {
                return res.data[0].discount;
            } else {
                showError("Такого промокода не существует");
                return 0;
            }
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function payCoursePersonal(payload: IPayloadCourses) {
        try {
            const res: IDirectusResponse<IResponsePayCourse> = await InterfaceApi.payCoursePersonal(payload);
            return res.data.id;
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function getInvoiceMail(payload: IPayloadCourses, company: any, selectedTariff: ISelectedTariff) {
        try {
            const res: IDirectusResponse<IResponsePayCourse> = await InterfaceApi.getInvoiceMail(payload);
            if (res.data.id) {
                const students = [];
                for (let item of payload.students) students.push(item.email);
                const payloadCourse: any = {
                    amount: payload.total_price,
                    courseTitle:
                        selectedTariff.course.title +
                        " c " +
                        formatDate(selectedTariff.course.start_date) +
                        " по " +
                        formatDate(selectedTariff.course.end_date) +
                        ". Тариф " +
                        selectedTariff.plan.title +
                        " Количество участников: " +
                        students.length,
                    inn: payload.corporate_inn,
                    invoiceNumber: "COURSES #" + res.data.id,
                    legalEntityString: company.data?.name.full_with_opf || null,
                    students: students,
                    manager: company.data.management.name || null,
                    studentsCount: students.length,
                };
                const download = await invoiceStore.downloadInvoicesCourse(payloadCourse);
                const anchorElement = document.createElement("a");
                document.body.appendChild(anchorElement);
                let blob = new Blob([download], {
                    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                });
                let url = window.URL.createObjectURL(blob);
                anchorElement.href = url;
                anchorElement.download = DOWNLOAD_NAME + "_" + company.data.name.short_with_opf + ".docx";
                anchorElement.click();
                window.URL.revokeObjectURL(url);
            }
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function fetchAll(link: string) {
        try {
            let responsePageData = await httpDirectus.get(`/Pages?filter[permalink][_eq]=${link}&fields=*.*.*.*.*.*.*`);
            if (responsePageData.data.data.length > 0) {
                pageData.value = responsePageData.data.data[0];
            } else {
                await router.replace({ name: ERoutesName.NOT_FOUND });
            }
            if (responsePageData.data.data[0].seo) {
                saveSeo(responsePageData.data.data[0].seo);
            }
            if (pageData.value.blocks.length == 0) return false;
            return true;
        } catch (e) {
            console.log("e", e);
            return false;
        }
    }

    return {
        headerItem,
        footerItem,
        footerCopyright,
        menuProfileItems,
        pageData,
        seo,

        getHeader,
        getFooter,
        applyPromocode,
        payCoursePersonal,
        getInvoiceMail,
        fetchAll,
    };
});
